export const schedules = [
  { value: "TODAY_ONLY", label: "Today Only" },
  { value: "TODAY_TOMORROW", label: "Today & Tomorrow" },
  { value: "WEEK", label: "Week" },
];

export const reasonsForVisit = [
  { value: "ILLNESS", label: "Illness" },
  { value: "INJURY", label: "Injury" },
  { value: "WOMEN'S HEALTH", label: "Women's Health" },
  { value: "LACERATION", label: "Laceration" },
  {
    value: "Winnipeg Area",
    label: "Winnipeg Area",
  },
  {
    value: "Brandon",
    label: "Brandon",
  },
  {
    value: "Winnipeg Area - Extended Hours Primary Care Clinics",
    label: "Winnipeg Area - Extended Hours Primary Care Clinics",
  },
  {
    value: "Brandon - Minor Injury and Illness Clinic",
    label: "Brandon - Minor Injury and Illness Clinic",
  },
  { value: "OTHER", label: "Other" },
];

export const lengths = [{ value: 15, label: "15 min" }];
