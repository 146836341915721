import { get, post, put, patch } from "../../Utils/HTTPMethods";

export const getPatientsByUserEmail = async (
  email,
  isActiveOnly = true,
  hidden = true
) => {
  return await get("QBookingResource", `/patient`, {
    email,
    isActiveOnly,
    hidden,
  });
};

export const getPatient = async (id) => {
  return await get("QBookingResource", `/patient`, { id });
};

export const createPatient = async (patient) => {
  return await post("QBookingResource", `/patient`, patient);
};

export const updatePatient = async (patient) => {
  return await put("QBookingResource", `/patient`, patient);
};

export const deactivatePatient = async (id) => {
  return await patch("QBookingResource", `/patient`, {
    id,
    isActive: false,
  });
};
