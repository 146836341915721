import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  severity: "success", // Can be 'success', 'error', 'warning', 'info'
  keepOpen: false, // If set to true, the snackbar will not auto-hide
};

const SnackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity || "success";
      state.keepOpen = action.payload.keepOpen || false;
    },
    closeSnackbar: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

export const { openSnackbar, closeSnackbar } = SnackbarSlice.actions;

export const selectSnackbar = (state) => state.snackbar;

export default SnackbarSlice.reducer;
