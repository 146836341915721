// Colours
import { emeraldGreen, offWhite } from "../../../../Constants/ColourConstants";

export const styles = {
  title: {
    color: emeraldGreen,
    paddingLeft: "1%",
    fontFamily: "Poppins, sans-serif",
    fontSize: 30,
    fontWeight: 600,
    cursor: "pointer",
  },
  mobileTitle: {
    color: emeraldGreen,
    fontSize: 22,
    fontWeight: "bold",
  },
  navBar: {
    background: offWhite,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 1000,
    justifyContent: "center",
  },
};
