// Redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patient: {},
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatient: (state, action) => {
      state.patient = action.payload;
    },
  },
});

export const selectPatient = (state) => state.patient.patient;

export const { setPatient } = patientSlice.actions;

export default patientSlice.reducer;
