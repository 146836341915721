// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setPatient } from "Store/PatientSlice";
import { selectUserEmail } from "Store/AuthSlice";
import { openSnackbar } from "Store/SnackbarSlice";
import { setClient } from "Store/ClientSlice";

// Components
import HowItWorksCard from "Components/HowItWorksCard";
import PatientCards from "../PatientCards";

// Resources
import { getPatientsByUserEmail } from "API/Resource/patient";
import { getClientByDomain } from "API/Resource/client";

// MUI
import {
  TextField,
  Button,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

// Styles
import { styles } from "./styles";

// Icons
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";

const PatientHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const email = useSelector(selectUserEmail);

  useEffect(() => {
    const domain = window.location.hostname;
    const fetchClient = async () => {
      const clientData = await getClientByDomain(domain);
      dispatch(setClient(clientData));
    };
    fetchClient();
  }, [dispatch]);

  useEffect(() => {
    handleFetchPatients();
  }, []);

  const handleFetchPatients = async () => {
    try {
      setIsLoading(true);
      let fetchedPatients = await getPatientsByUserEmail(email);
      setPatients(fetchedPatients);
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const filteredPatients = patients.filter((patient) => {
    const name = `${patient.firstName} ${patient.lastName}`;
    return name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <>
      <HowItWorksCard />
      <Grid container sx={styles.searchGrid}>
        <Grid xs={12} md={6}>
          <TextField
            placeholder="Search Patients"
            variant="outlined"
            fullWidth
            size="small"
            sx={styles.searchField}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Button
            variant="contained"
            sx={styles.addPatientButton}
            onClick={() => {
              dispatch(setPatient({}));
              navigate("/PatientEdit");
            }}
          >
            <PersonAddIcon sx={styles.personAddIcon} />
            <Typography sx={styles.buttonText}>ADD NEW PATIENT</Typography>
          </Button>
        </Grid>
      </Grid>
      {isLoading ? (
        <CircularProgress sx={styles.loading} />
      ) : (
        <PatientCards
          patients={filteredPatients}
          fetchPatients={handleFetchPatients}
        />
      )}
    </>
  );
};

export default PatientHome;
