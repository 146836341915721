// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Amplify
import { signIn, resendSignUpCode } from "aws-amplify/auth";

// Material UI
import {
  Container,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  MailOutline,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// Formik
import { useFormik } from "formik";

// Components
import ConfirmSignUp from "../ConfirmSignUp";

// Redux
import { useDispatch } from "react-redux";
import { setAuthState, setUserEmail } from "../../../Store/AuthSlice";
import { openSnackbar } from "Store/SnackbarSlice";

// Styles
import { styles } from "./styles";

// Validation
import { signinValidationSchema } from "Validation/UserValidation";

const SignIn = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  const handleSignIn = async (email, password) => {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password,
      });
      dispatch(setUserEmail(email));
      if (!isSignedIn) {
        switch (nextStep.signInStep) {
          case "CONFIRM_SIGN_UP":
            setConfirmOpen(true);
            break;
          default:
            setConfirmOpen(true);
            break;
        }
      } else {
        dispatch(setAuthState("SignedIn"));
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleForgotPassword = () => {
    dispatch(setAuthState("ResetPassword"));
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signinValidationSchema,
    onSubmit: (values) => {
      handleSignIn(values.email, values.password);
    },
  });

  return (
    <Container sx={styles.noPadding}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.formBox}>
        <Box sx={styles.textFieldsBox}>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handlePasswordToggle}
                      onMouseDown={handlePasswordToggle}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        <Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={styles.signinBtn}
          >
            Sign In
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={handleForgotPassword}
            sx={styles.forgotPasswordBtn}
          >
            Forgot your password?
          </Button>
        </Box>
      </Box>

      <ConfirmSignUp
        open={confirmOpen}
        onClose={handleConfirmClose}
        password={formik.values.password}
      />
    </Container>
  );
};

export default SignIn;
