// Colours
import {
  terracotta,
  offWhite,
  textGrey,
} from "../../../Constants/ColourConstants";

// Material UI
import { Tab, styled } from "@mui/material";

export const styles = {
  authBox: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100vw",
    gap: "4vh",
    background: offWhite,
  },
  contentBox: {
    marginLeft: "auto",
    marginRight: "auto",
    spacing: "26px",
    display: "flex",
    flexDirection: "column",
    width: {
      xs: "75%",
      md: "50%",
      lg: "30%",
    },
    gap: "4vh",
  },
  staticMessageBox: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    width: {
      xs: "100%",
      md: "75%",
      lg: "50%",
    },
  },
  staticMessage: {
    mx: 2,
    color: textGrey,
  },
};

export const StyledTab = styled(Tab)((index) => ({
  id: `simple-tab-${index}`,
  ariaControls: `simple-tabpanel-${index}`,
  color: "rgba(0, 0, 0, 0.6)",
  fontWeight: "bold",
  "&.Mui-selected": { color: terracotta },
}));
