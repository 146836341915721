import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userEmail: null,
  userGroup: null,
  authState: "SignIn",
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserGroup: (state, action) => {
      state.userGroup = action.payload;
    },
    setAuthState: (state, action) => {
      state.authState = action.payload;
    },
  },
});

export const { setUserEmail, setUserGroup, setAuthState } = AuthSlice.actions;

export const selectUserEmail = (state) => state.auth.userEmail;
export const selectUserGroup = (state) => state.auth.userGroup;
export const selectAuthState = (state) => state.auth.authState;

export default AuthSlice.reducer;
