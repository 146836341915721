// React
import React, { useState } from "react";

// Material UI
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Check,
  Clear,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// Styles
import { styles } from "./styles";

// Colours
import { grey } from "Constants/ColourConstants";

const PasswordField = ({ formik, isMobile }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasUpper: false,
    hasLower: false,
    hasNumberOrSpecial: false,
    isMinLength: false,
  });

  const isPasswordValid =
    passwordRequirements.hasUpper &&
    passwordRequirements.hasLower &&
    passwordRequirements.hasNumberOrSpecial &&
    passwordRequirements.isMinLength;
  const generateHelperText = () => {
    if (
      formik.touched.password &&
      formik.errors.password &&
      !formik.values.password
    ) {
      return formik.errors.password;
    }

    if (formik.values.password && !isPasswordValid) {
      return (
        <>
          <Box sx={styles.requirement}>
            {passwordRequirements.isMinLength ? (
              <Check color="success" sx={styles.requirementIcon} />
            ) : (
              <Clear color="error" sx={styles.requirementIcon} />
            )}{" "}
            <Typography variant="caption" sx={{ color: grey }}>
              Minimum 8 characters
            </Typography>
          </Box>
          <Box sx={styles.requirement}>
            {passwordRequirements.hasUpper ? (
              <Check color="success" sx={styles.requirementIcon} />
            ) : (
              <Clear color="error" sx={styles.requirementIcon} />
            )}{" "}
            <Typography variant="caption" sx={{ color: grey }}>
              At least 1 uppercase letter
            </Typography>
          </Box>
          <Box sx={styles.requirement}>
            {passwordRequirements.hasLower ? (
              <Check color="success" sx={styles.requirementIcon} />
            ) : (
              <Clear color="error" sx={styles.requirementIcon} />
            )}{" "}
            <Typography variant="caption" sx={{ color: grey }}>
              At least 1 lowercase letter
            </Typography>
          </Box>
          <Box sx={styles.requirement}>
            {passwordRequirements.hasNumberOrSpecial ? (
              <Check color="success" sx={styles.requirementIcon} />
            ) : (
              <Clear color="error" sx={styles.requirementIcon} />
            )}{" "}
            <Typography variant="caption" sx={{ color: grey }}>
              At least 1 number or special character
            </Typography>
          </Box>
        </>
      );
    }

    return "";
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;

    setPasswordRequirements({
      hasUpper: /[A-Z]/.test(password),
      hasLower: /[a-z]/.test(password),
      hasNumberOrSpecial: /[0-9!@#$%^&*(),.?":{}|<>]/.test(password),
      isMinLength: password.length >= 8,
    });

    formik.handleChange(e);
  };

  return (
    <Box>
      <TextField
        id="password"
        name="password"
        label="Password"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        fullWidth
        required
        size={isMobile ? "small" : "medium"}
        value={formik.values.password}
        onChange={handlePasswordChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.password &&
          (!isPasswordValid || Boolean(formik.errors.password))
        }
        helperText={generateHelperText()}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handlePasswordToggle}
                  onMouseDown={handlePasswordToggle}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          },
          formHelper: {
            color: grey,
          },
        }}
      />
    </Box>
  );
};

export default PasswordField;
