// MFA styles
import { terracotta, emeraldGreen } from "Constants/ColourConstants";

export const styles = {
  dialogPaper: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: 725, // Set your width here
      },
    },
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alignItems: "center",
  },
  dialogTitleText: {
    color: emeraldGreen,
    fontWeight: "600",
    fontSize: "20px",
    textAlign: "left",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  dialogContentText: {
    font: "Roboto",
    color: "#6B6B6B",
    fontWeight: "400",
    fontSize: "16px",
    textAlign: "left",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
  },
  radioButton: {
    "&.Mui-checked": {
      color: terracotta,
    },
  },
  dialogActions: {
    padding: "4px 24px 24px", // Padding: no top (0), 24px on sides, 24px on bottom
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px", // Consistent gap between buttons
  },
  cancelButton: {
    borderRadius: "50px",
    padding: "8px 24px",
    minWidth: "150px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  submitButton: {
    borderRadius: "50px",
    padding: "8px 24px",
    minWidth: "150px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  backButton: {
    backgroundColor: terracotta,
    width: 100,
    borderRadius: 30,
    justifyContent: "space-between",
  },
};
