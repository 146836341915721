import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";

// MUI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Components
import ViewMoreAppointmentsModal from "../ViewMoreAppointmentsModal";
import BookAppointmentModal from "../BookAppointmentModal/BookAppointmentModal";
import AvailableAppointmentsMobile from "./AvailableAppointmentsMobile";
import TruncateText from "../../../../../Common/TruncateText";
import { DateTime } from "luxon";

// Styles
import { styles } from "../styles";

// Constants
const MOBILEVIEW = 900;

const AvailableAppointments = () => {
  const navigate = useNavigate();
  const { reasonForVisit, additionalInfo, availableSlots } =
    useLocation().state;
  const [searchClinic, setSearchClinic] = useState("");
  const [filteredAvailableSlots, setFilteredAvailableSlots] =
    useState(availableSlots);
  const [gridHeight, setGridHeight] = useState(169);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openBookAppointmentModal, setOpenBookAppointmentModal] =
    useState(false);
  const [openViewMoreModal, setOpenViewMoreModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const patient = useSelector(selectPatient);

  const handleViewMoreModal = (row) => {
    setSelectedRow(row);
    setOpenViewMoreModal(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Clinic",
      minWidth: 200,
      flex: 2,
      valueGetter: (_, row) => row?.Clinic?.name,
      renderCell: (params) => (
        <TruncateText text={params.row?.Clinic?.name || ""} />
      ),
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 190,
      flex: 1.75,
      valueGetter: (_, row) => row?.Clinic?.location,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" sx={styles.location}>
          <LocationOnIcon />
          <TruncateText text={params.row?.Clinic?.location || ""} />
        </Box>
      ),
    },
    {
      field: "earliestDate",
      headerName: "Earliest Date",
      minWidth: 150,
      flex: 1,
      valueGetter: (_, row) => row?.availableSlots?.[0]?.date,
      renderCell: (params) => (
        <TruncateText
          text={
            DateTime.fromISO(params.row?.availableSlots?.[0]?.date).toFormat(
              "MMMM dd, yyyy"
            ) || ""
          }
        />
      ),
    },
    {
      field: "earliestTime",
      headerName: "Earliest Time",
      minWidth: 100,
      flex: 0.9,
      valueGetter: (_, row) => row?.availableSlots?.[0]?.startTime,
      renderCell: (params) => {
        return (
          <TruncateText text={params.row?.availableSlots?.[0]?.startTime} />
        );
      },
    },
    {
      field: "providerName",
      headerName: "Physician",
      minWidth: 160,
      flex: 1,
      valueGetter: (_, row) => row?.availableSlots?.[0]?.providerName,
      renderCell: (params) => (
        <TruncateText
          text={params.row?.availableSlots?.[0]?.providerName || ""}
        />
      ),
    },
    {
      field: "status",
      headerName: "Actions",
      minWidth: 230,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const status = params.row?.showWaitlist;
        if (status) {
          return (
            <Button
              variant="contained"
              sx={styles.joinWaitlistButton}
              color="primary"
              onClick={() => console.log("Join Waitlist")}
            >
              Join Waitlist
            </Button>
          );
        } else {
          return (
            <Box sx={styles.bookViewMoreBox}>
              <Button
                variant="contained"
                sx={styles.bookButton}
                color="primary"
                onClick={() => createAppointmentDetails(params.row)}
              >
                Book
              </Button>
              <Button
                variant="outlined"
                disabled={params.row?.availableSlots.length === 1}
                sx={styles.viewMoreButton}
                onClick={() => handleViewMoreModal(params.row)}
              >
                View More
              </Button>
            </Box>
          );
        }
      },
    },
  ];

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update screenWidth
  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  // Filter appointment slots based on search input
  useEffect(() => {
    const filteredSlots = searchClinic
      ? availableSlots.filter((row) =>
          row.Clinic.name.toLowerCase().includes(searchClinic.toLowerCase())
        )
      : availableSlots;

    setFilteredAvailableSlots(filteredSlots);
  }, [searchClinic, availableSlots]);
  // Calculate grid height based on number of rows
  useEffect(() => {
    const baseHeight = 169;
    const increment = 60;
    const n = Math.min(Math.max(filteredAvailableSlots.length, 1), 10); // Ensure n is at least 1 and at most 10

    let calculatedHeight = baseHeight + (n - 1) * increment;

    // Add 20px if screen width is less than 1240 to accommodate for horizontal scrollbar
    if (screenWidth < 1240) {
      calculatedHeight += 20;
    }

    setGridHeight(calculatedHeight);
  }, [filteredAvailableSlots, screenWidth]);

  // Dynamically calculate the max width of the inner box based on screen width
  const calculateInnerBoxMaxWidth = () => {
    const threshold = 1375;
    const minScreenWidth = 950;
    const baseWidth = 80;

    if (screenWidth < threshold) {
      const widthBelowThreshold = threshold - screenWidth;
      // Calculate extra percentage to add (gradually from 80% to 100%)
      const extraPercentage = Math.min(
        (widthBelowThreshold / (threshold - minScreenWidth)) * 20, // Scale from 0% to 20% increase
        20 // Cap it at 20% extra
      );
      return `calc(${baseWidth}% + ${extraPercentage}%)`;
    }

    return `${baseWidth}%`;
  };

  const createAppointmentDetails = (row) => {
    const appointment = {
      patientId: patient.id,
      clinicId: row.Clinic.id,
      clinicName: row.Clinic.name,
      address: row.Clinic.location,
      physician: row.availableSlots[0].providerName,
      date: DateTime.fromISO(row.availableSlots[0].date).toFormat(
        "MMM d, yyyy"
      ),
      startTime: row.availableSlots[0].startTime,
      endTime: row.availableSlots[0].endTime,
      type: reasonForVisit,
      notes: `Online Booking: ${additionalInfo}`,
      status: "BOOKED",
      accuroDetails: {
        subColumn: row.availableSlots[0].subColumn,
        providerId: row.availableSlots[0].providerId,
      },
    };
    bookAppointment(appointment);
  };

  const bookAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setOpenBookAppointmentModal(true);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerBox,
          maxWidth: calculateInnerBoxMaxWidth(),
        }}
      >
        <Box sx={styles.headerBox}>
          <Button
            variant="contained"
            sx={styles.backButton}
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIcon sx={styles.backArrow} />
            Back
          </Button>
          <Typography sx={styles.header}>AVAILABLE APPOINTMENTS</Typography>
        </Box>
        <Box sx={styles.patientNameBox}>
          <Typography sx={styles.patientName}>
            Patient: {patient.firstName} {patient.lastName}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          {screenWidth < MOBILEVIEW ? (
            <AvailableAppointmentsMobile
              availableAppointments={filteredAvailableSlots}
              createAppointmentDetails={createAppointmentDetails}
              handleViewMoreModal={handleViewMoreModal}
              searchClinic={searchClinic}
              setSearchClinic={setSearchClinic}
            />
          ) : (
            <Paper sx={styles.availableAppointmentsPaper}>
              <Box sx={{ width: "100%" }}>
                <Box sx={styles.textFieldBox}>
                  <TextField
                    id="outlined-basic"
                    label="Search Clinics"
                    variant="outlined"
                    size="small"
                    sx={{
                      ...styles.textField,
                      width: "20%",
                    }}
                    value={searchClinic}
                    onChange={(e) => setSearchClinic(e.target.value)}
                  />
                </Box>
                <Box sx={styles.dataGridContainer}>
                  <DataGrid
                    rows={filteredAvailableSlots}
                    columns={columns}
                    rowHeight={60}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10]}
                    disableSelectionOnClick
                    onCellClick={(params, event) => event.stopPropagation()}
                    localeText={{
                      noRowsLabel: `No Appointments Available`,
                    }}
                    sx={{ ...styles.dataGrid, height: `${gridHeight}px` }}
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
      {openViewMoreModal && (
        <ViewMoreAppointmentsModal
          closeModal={() => setOpenViewMoreModal(false)}
          row={selectedRow}
          bookAppointment={bookAppointment}
          reasonForVisit={reasonForVisit}
          additionalInfo={additionalInfo}
        />
      )}
      {openBookAppointmentModal && (
        <BookAppointmentModal
          closeModal={() => setOpenBookAppointmentModal(false)}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default AvailableAppointments;
