// Redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
  },
});

export const selectClient = (state) => state.client.client;

export const { setClient } = clientSlice.actions;

export default clientSlice.reducer;
