// Colours
import { emeraldGreen, terracotta } from "Constants/ColourConstants";

export const styles = {
  pageBox: {
    maxWidth: "1000px",
    ml: "auto",
    mr: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    my: 2,
  },
  cardContainer: {
    borderRadius: 4,
    background: "white",
    pt: 2,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 20,
    color: emeraldGreen,
    textAlign: "left",
  },
  submitButton: {
    borderRadius: 30,
    background: emeraldGreen,
    width: 125,
  },
  mainButton: {
    borderRadius: 30,
    background: terracotta,
    width: 125,
  },
  rowBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  subtitle: {
    textTransform: "uppercase",
    fontWeight: "medium",
    fontSize: 16,
    color: terracotta,
    textAlign: "left",
  },
  suggestionRow: {
    marginBottom: 2,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  fab: {
    color: "white",
    background: emeraldGreen,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    ml: "auto",
  },
  secondaryButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
    width: 125,
  },
  okButton: {
    borderRadius: 30,
    background: terracotta,
    width: 150,
    mx: "auto",
  },
};
