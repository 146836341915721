// React
import React from "react";

// Material UI
import { Grid2, Typography, Box, Card } from "@mui/material";

// styles
import { styles } from "./styles";

const HowItWorksCard = () => {
  return (
    <Card sx={styles.card}>
      <Typography sx={styles.title}>How It Works</Typography>

      <Grid2 container sx={styles.stepsGrid}>
        <Grid2 container sx={styles.stepGrid}>
          <Box sx={styles.iconBox}>
            <img alt="Profile icon" src="/profile.png" />
          </Box>
          <Box sx={styles.typographyBox}>
            <Typography sx={styles.secondaryTitle}>Step 1</Typography>
            <Typography sx={styles.description}>
              Create patient profile
            </Typography>
          </Box>
        </Grid2>

        <Grid2 container sx={styles.stepGrid}>
          <Box sx={styles.iconBox}>
            <img alt="Search icon" src="/search.png" />
          </Box>
          <Box sx={styles.typographyBox}>
            <Typography sx={styles.secondaryTitle}>Step 2</Typography>
            <Typography sx={styles.description}>
              Search for available appointments
            </Typography>
          </Box>
        </Grid2>

        <Grid2 container sx={styles.stepGrid}>
          <Box sx={styles.iconBox}>
            <img alt="Calendar icon" src="/calendar.png" />
          </Box>
          <Box sx={styles.typographyBox}>
            <Typography sx={styles.secondaryTitle}>Step 3</Typography>
            <Typography sx={styles.description}>Book an appointment</Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Card>
  );
};

export default HowItWorksCard;
