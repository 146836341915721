import { terracotta } from "Constants/ColourConstants";

export const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    width: "50%",
    minWidth: "350px",
    maxHeight: "85vh",
    display: "flex",
    flexDirection: "column",
    padding: 2,
    position: "relative",
    overflow: "hidden",
    zIndex: 1400,
  },
  header: {
    marginTop: ".5rem",
    marginBottom: "1rem",
  },
  clinicName: {
    color: "#225729",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "left",
  },
  tabsContainer: {
    overflowX: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: ".75rem",
  },
  dateTabs: {
    display: "flex",
    flexGrow: 1,
    maxWidth: "fit-content",
    justifyContent: "center",
  },
  periodTabsContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "1.25rem",
  },
  periodTabs: {
    color: "rgba(0, 0, 0, 0.6)",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& .Mui-selected": { color: terracotta },
  },
  timeSlotsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    flexWrap: "wrap",
    overflowY: "auto",
    marginBottom: "1rem",
    height: "auto",
    maxHeight: "40vh",
    padding: "1rem 1rem",
    "@media (max-height: 700px)": {
      maxHeight: "27vh",
    },
  },
  timeSlotButton: {
    flex: "1",
    borderRadius: "50px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "80px",
    maxWidth: "80px",
    backgroundColor: "white",
    color: "#225729",
    padding: "4px 0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
    },
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingBottom: "1rem",
    marginTop: "auto",
    gap: "25px",
  },
  cancelButton: {
    borderRadius: "50px",
    padding: "8px 24px",
    borderColor: "#225729",
    color: "#225729",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    width: "150px",
  },
  bookButton: {
    borderRadius: "50px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    width: "150px",
  },
};
