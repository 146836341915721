import {
  get as apiGet,
  post as apiPost,
  put as apiPut,
  del as apiDel,
  patch as apiPatch,
} from "aws-amplify/api";
import { APP_VERSION } from "config";
import { versionMismatchAlert } from "Utils/Version/VersionMismatch";

// HTTP GET request
export const get = async (apiName, path, params) => {
  const queryParams = { ...params, version: APP_VERSION };
  try {
    const restOperation = apiGet({ apiName, path, options: { queryParams } });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    if (error.response) {
      const errorBody = JSON.parse(error.response.body);
      if (errorBody.error === "VERSION_MISMATCH") {
        versionMismatchAlert();
      } else {
        throw new Error(errorBody.error);
      }
    }
    throw error;
  }
};

// HTTP POST request
export const post = async (apiName, path, body) => {
  const queryParams = { version: APP_VERSION };
  try {
    const restOperation = apiPost({
      apiName,
      path,
      options: { body, queryParams },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    if (error.response) {
      const errorBody = JSON.parse(error.response.body);
      if (errorBody.error === "VERSION_MISMATCH") {
        versionMismatchAlert();
      } else {
        throw new Error(errorBody.error);
      }
    }
    throw error;
  }
};

// HTTP PUT request
export const put = async (apiName, path, body) => {
  const queryParams = { version: APP_VERSION };
  try {
    const restOperation = apiPut({
      apiName,
      path,
      options: { body, queryParams },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    if (error.response) {
      const errorBody = JSON.parse(error.response.body);
      if (errorBody.error === "VERSION_MISMATCH") {
        versionMismatchAlert();
      } else {
        throw new Error(errorBody.error);
      }
    }
    throw error;
  }
};

// HTTP DELETE request
export const del = async (apiName, path, params) => {
  const queryParams = { ...params, version: APP_VERSION };
  try {
    const restOperation = await apiDel({
      apiName,
      path,
      options: { queryParams },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    if (error.response) {
      const errorBody = JSON.parse(error.response.body);
      if (errorBody.error === "VERSION_MISMATCH") {
        versionMismatchAlert();
      } else {
        throw new Error(errorBody.error);
      }
    }
    throw error;
  }
};

// HTTP PATCH request
export const patch = async (apiName, path, body) => {
  const queryParams = { version: APP_VERSION };
  try {
    const restOperation = await apiPatch({
      apiName,
      path,
      options: { body, queryParams },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    if (error.response) {
      const errorBody = JSON.parse(error.response.body);
      if (errorBody.error === "VERSION_MISMATCH") {
        versionMismatchAlert();
      } else {
        throw new Error(errorBody.error);
      }
    }
    throw error;
  }
};
