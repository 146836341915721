import React, { useState, useRef, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import { styles } from "../Components/Users/Patient/Appointments/styles";

const TruncateText = ({ text }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflow(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, [text]);

  return isOverflow ? (
    <Tooltip title={text}>
      <Box sx={styles.truncatedText} ref={textRef}>
        {text}
      </Box>
    </Tooltip>
  ) : (
    <Box sx={styles.truncatedText} ref={textRef}>
      {text}
    </Box>
  );
};

export default TruncateText;
