// Colours
import { terracotta, emeraldGreen, white } from "Constants/ColourConstants";

export const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: 3,
    },
    backdropFilter: "blur(3px)",
  },
  actionsContainer: {
    pb: 2,
  },
  signInButton: {
    borderRadius: 30,
    background: white,
    color: emeraldGreen,
    borderColor: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  signOutButton: {
    borderRadius: 30,
    background: terracotta,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
};
