// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Amplify
import { getCurrentUser } from "aws-amplify/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectAuthState, setAuthState } from "../../../Store/AuthSlice";

// Components
import SignUp from "../SignUp";
import SignIn from "../SignIn";
import SignedIn from "../SignedIn";
import ResetPassword from "../ResetPassword";
import { AuthHeader } from "../Components/AuthHeader";
import { AuthFooter } from "../Components/AuthFooter";

// Material UI
import { Tabs, Box, Typography } from "@mui/material";

// Colours
import { terracotta } from "../../../Constants/ColourConstants";

// Styles
import { styles, StyledTab } from "./styles";

const CustomTabPanel = (props) => {
  const { children, value, index, tabLabel, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`${tabLabel}-tab`}
      {...other}
    >
      {value === index && (
        <Box paddingLeft={0} paddingRight={0}>
          {children}
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  tabLabel: PropTypes.string.isRequired,
};

const AuthContainer = () => {
  const authState = useSelector(selectAuthState);
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    checkCurrentUser();
  }, []);

  const checkCurrentUser = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        dispatch(setAuthState("SignedIn"));
      }
    } catch {
      dispatch(setAuthState("SignIn"));
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    dispatch(setAuthState(newValue === 0 ? "SignIn" : "SignUp"));
  };

  const SigninSignup = () => {
    return (
      <>
        <Box sx={styles.contentBox} gap={tabValue === 0 ? "6vh" : "2vh"}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Sign in and Sign up Tabs"
              variant="fullWidth"
              TabIndicatorProps={{ sx: { backgroundColor: terracotta } }}
            >
              <StyledTab index={0} label="Sign In" />
              <StyledTab index={1} label="Sign Up" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0} tabLabel="sign-in">
            <SignIn />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1} tabLabel="sign-up">
            <SignUp />
          </CustomTabPanel>
        </Box>
        {authState === "SignIn" && (
          <Box sx={styles.staticMessageBox}>
            <Typography sx={styles.staticMessage}>
              Please note we have transitioned our booking system to Medical
              Navigator. If you do not already have a user account with Medical
              Navigator, you will be required to create one in order to book an
              appointment.
            </Typography>
          </Box>
        )}
      </>
    );
  };

  switch (authState) {
    case "SignedIn":
      return <SignedIn />;
    case "ResetPassword":
      return (
        <Box xs={12} md={6} sx={styles.authBox}>
          <AuthHeader />
          <ResetPassword />
          <AuthFooter />
        </Box>
      );
    default:
      return (
        <Box xs={12} md={6} sx={styles.authBox}>
          <AuthHeader />
          <SigninSignup />
          <AuthFooter />
        </Box>
      );
  }
};

export default AuthContainer;
