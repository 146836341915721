import { get, post, patch } from "../../Utils/HTTPMethods";

export const getAppointmentsByPatientId = async (patientId) => {
  return await get("QBookingResource", `/appointment`, { patientId });
};

/**
 * @param {String} ageGroup - one from  ALL, YOUNG, ADULT
 * @param {String} reasonForVisit - the reason for the visit (clinic.appointmentSuggestions[].type)
 * @return - all free appointments for all clinics and reason for visit types
 */
export const getAvailableAppointments = async (
  ageGroup = "ALL",
  reasonForVisit = null
) => {
  return await get("QBookingResource", `/appointment`, {
    ageGroup,
    reasonForVisit,
  });
};

export const bookAppointment = async (appointment) => {
  return await post("QBookingResource", `/appointment`, appointment);
};

export const cancelAppointment = async (id) => {
  return await patch("QBookingResource", `/appointment`, {
    id,
    status: "CANCELLED",
    action: "cancel",
  });
};
