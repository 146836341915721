// React
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  Box,
  IconButton,
  Typography,
  Container,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import {
  AddRounded,
  CancelRounded,
  SearchRounded,
  ModeEditRounded,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

// Styles
import { styles } from "./styles";

// Components
import ClinicManagement from "../ClinicManagement";
import { getAllClients } from "API/Resource/client";

const ClientManagement = () => {
  const [allClients, setAllClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientSearch, setClientSearch] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  const clinicManagementRef = useRef(null);
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "domain",
      headerName: "Domain",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "appointmentFilter",
      headerName: "Appointment Filter",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "appointmentLocations",
      headerName: "Appointment Locations",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <>
              {params.row.appointmentLocations.map((location, index) => (
                <Typography key={index}>{location}</Typography>
              ))}
            </>
          }
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.appointmentLocations.join(", ")}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "appointmentReasons",
      headerName: "Appointment Reasons",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <>
              {params.row.appointmentReasons.map((reason, index) => (
                <Typography key={index}>{reason}</Typography>
              ))}
            </>
          }
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.appointmentReasons.join(", ")}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <Box sx={styles.actionsBox}>
            <Tooltip title="Edit client" placement="top">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate("/EditClient", { state: { client: params.row } })
                }
                sx={{ my: "auto" }}
              >
                <ModeEditRounded />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchClients = async () => {
      const response = await getAllClients();
      setAllClients(response);
      setClients(response);
      setLoading(false);
    };
    fetchClients();
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value || "";
    setClientSearch(searchValue);
    filterClients(searchValue);
  };

  const filterClients = (searchValue) => {
    const filtered = allClients.filter((client) =>
      client.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setClients(filtered);
  };

  const handleClearSearch = () => {
    setClientSearch("");
    setClients(allClients);
  };

  const handleRowClick = (event) => {
    if (event.row.id === selectedClient?.id) {
      setSelectedClient(null);
    } else {
      setSelectedClient(event.row);
      scrollToClinicManagement();
    }
  };

  const scrollToClinicManagement = () => {
    if (clinicManagementRef) {
      clinicManagementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Container sx={styles.pageBox}>
      <Box sx={styles.titleBox}>
        <Typography sx={styles.title}>Client Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          sx={styles.addButton}
          onClick={() => navigate("/AddClient")}
        >
          Add Client
        </Button>
      </Box>

      <Container sx={styles.contentBox}>
        <TextField
          name="searchClient"
          placeholder="Search Client"
          type="text"
          variant="standard"
          value={clientSearch}
          onChange={handleSearch}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clear search bar"
                    onClick={handleClearSearch}
                    edge="end"
                    size="small"
                  >
                    <CancelRounded fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          sx={{ width: 250, ml: "auto" }}
        />
        <DataGrid
          rows={clients}
          columns={columns}
          disableMultipleRowSelection
          rowSelectionModel={selectedClient ? [selectedClient.id] : []}
          onRowClick={handleRowClick}
          rowHeight={60}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5]}
          loading={loading}
          sx={styles.datagrid}
        />
      </Container>
      <div ref={clinicManagementRef}>
        <ClinicManagement client={selectedClient} />
      </div>
    </Container>
  );
};

export default ClientManagement;
