// Theme Colours
export const terracotta = "#CB6843";
export const emeraldGreen = "#225729";
export const offWhite = "#F2F0EF";
export const deleteRed = "#D32F2F";
export const disabledText = "#9E9E9E";
export const grey = "#676F76";
export const white = "#FFFFFF";
export const dateNotAvailableGrey = "#00000061";
export const dateNotSelectedGrey = "#00000099";
export const textGrey = "#00000099";

// Alert Colours
export const alertError = "#e39e84";
export const alertSuccess = "#91c498";
export const alertWarning = "#edd57e";
export const alertInfo = "#86b4db";
