// React
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Resources
import { createClinic, updateClinic } from "API/Resource/clinic";
import { getAllClients } from "API/Resource/client";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Material UI
import {
  Box,
  Typography,
  Container,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Add, ClearRounded, ExpandMore } from "@mui/icons-material";

// Formik
import { FieldArray, Formik } from "formik";

// Validation
import { clinicValidationSchema } from "Validation/ClinicValidation";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// Constants
import { provinces } from "Constants/ProvinceConstants";
import { schedules, lengths } from "Constants/ClinicConstants";

// Styles
import { styles } from "./styles";
import { emeraldGreen } from "Constants/ColourConstants";

// Form default values
const defaultValues = {
  name: "",
  address: "",
  city: "",
  postalCode: "",
  province: "",
  phone: "",
  altPhone: "",
  appointmentSchedule: schedules[0].value,
  appointmentSuggestions: [{ suggestion: "", type: [], length: "" }],
  accuroClientId: "",
  accuroClientSecret: "",
  accuroSubscriptionKey: "",
  accuroUUID: "",
  accuroOfficeName: "",
  clientId: "",
};

const ClinicInfo = () => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const clinicProp = location.state?.clinic;
  const [clinicValues, setClinicValues] = useState(defaultValues);

  useEffect(() => {
    const fetchClientData = async () => {
      const response = await getAllClients(false);

      // if editing, set client and initial appointment types
      if (clinicProp) {
        if (clinicProp.clientId) {
          const client = response.find(
            (client) => client.id === clinicProp.clientId
          );
          const apptTypes =
            client.appointmentFilter === "LOCATION"
              ? client.appointmentLocations
              : client.appointmentReasons;

          // clear types if the client's appointment types has changed
          const updatedSuggestions = clinicProp.appointmentSuggestions.map(
            (suggestion) => {
              if (suggestion.type.some((type) => !apptTypes.includes(type))) {
                return { ...suggestion, type: [] };
              }
              return suggestion;
            }
          );
          const updatedClinic = {
            ...clinicProp,
            appointmentSuggestions: updatedSuggestions,
          };
          setClinicValues(updatedClinic);
          setAppointmentTypes(apptTypes);
        } else {
          setClinicValues(clinicProp);
        }
      } else {
        setClinicValues(defaultValues);
      }

      setAllClients(response);
      setLoadingClients(false);
    };
    fetchClientData();
  }, [clinicProp]);

  const handleClinicSave = async (values) => {
    clinicProp
      ? await handleUpdateClinic(values)
      : await handleCreateClinic(values);
  };

  const handleCreateClinic = async (values) => {
    try {
      await createClinic(values);
      setConfirmationOpen(true);
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleUpdateClinic = async (values) => {
    const changes = Object.keys(values).reduce((acc, key) => {
      if (typeof values[key] === "object") {
        if (JSON.stringify(values[key]) !== JSON.stringify(clinicProp[key])) {
          acc[key] = values[key];
        }
      } else if (values[key] !== clinicProp[key]) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    try {
      if (Object.keys(changes).length > 0) {
        await updateClinic({ id: clinicProp.id, ...changes });
      }
      dispatch(
        openSnackbar({
          message: "Clinic successfully updated!",
          severity: "success",
        })
      );
      navigateToClientManagement();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const navigateToClientManagement = () => {
    navigate("/ClientManagement");
  };

  const handleChangeClient = (event, formik) => {
    formik.handleChange(event);

    // set appointment types
    const client = allClients.find(
      (client) => client.id === event.target.value
    );
    const apptTypes =
      client.appointmentFilter === "LOCATION"
        ? client.appointmentLocations
        : client.appointmentReasons;
    setAppointmentTypes(apptTypes);

    // clear selected types
    formik.setFieldValue(
      "appointmentSuggestions",
      formik.values.appointmentSuggestions.map((suggestion) => ({
        ...suggestion,
        type: [],
      }))
    );
  };

  const ConfirmationDialog = ({ clinicName }) => {
    return (
      <Dialog
        open={confirmationOpen}
        onSubmit={navigateToClientManagement}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle color={emeraldGreen} sx={{ textTransform: "uppercase" }}>
          Success!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clinic <strong>{clinicName}</strong> was successfully created.{" "}
            <br />
            To activate it, please head to the <strong>Inactive</strong> tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmationOpen(false);
              navigateToClientManagement();
            }}
            sx={styles.okButton}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={clinicValues ?? defaultValues}
      enableReinitialize={true}
      validationSchema={clinicValidationSchema}
      onSubmit={(values) => {
        const formattedValues = {
          ...values,
          phone: parsePhoneNumberFromString(
            values.phone,
            "CA"
          )?.formatNational(),
          altPhone: parsePhoneNumberFromString(
            values.altPhone,
            "CA"
          )?.formatNational(),
        };
        handleClinicSave(formattedValues);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <ConfirmationDialog clinicName={formik.values.name} />

          <Box sx={styles.pageBox}>
            <Typography sx={styles.title}>Clinic Information</Typography>
            <Container sx={styles.cardContainer}>
              <Typography sx={styles.title}>General</Typography>
              <TextField
                id="name"
                name="name"
                label="Name"
                type="text"
                variant="outlined"
                fullWidth
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Box sx={styles.rowBox}>
                <TextField
                  id="address"
                  name="address"
                  label="Address"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Box>
              <Box sx={styles.rowBox}>
                <TextField
                  id="postalCode"
                  name="postalCode"
                  label="Postal Code"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
                <FormControl
                  fullWidth
                  required
                  error={
                    formik.touched.province && Boolean(formik.errors.province)
                  }
                >
                  <InputLabel>Province</InputLabel>
                  <Select
                    id="province"
                    value={formik.values.province}
                    label="Province"
                    onChange={formik.handleChange("province")}
                    sx={{ textAlign: "left" }}
                  >
                    {provinces.map((location, index) => (
                      <MenuItem key={index} value={location.code}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.province &&
                    Boolean(formik.errors.province) && (
                      <FormHelperText error>
                        {formik.errors.province}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <Box sx={styles.rowBox}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  id="altPhone"
                  name="altPhone"
                  label="Alt. Phone Number"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={formik.values.altPhone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.altPhone && Boolean(formik.errors.altPhone)
                  }
                  helperText={formik.touched.altPhone && formik.errors.altPhone}
                />
              </Box>
            </Container>

            {/* Client */}
            <Container sx={styles.cardContainer}>
              <Typography sx={styles.title}>Client</Typography>
              <FormControl required>
                <InputLabel>Client</InputLabel>
                <Select
                  label="client"
                  id="clientId"
                  name="clientId"
                  value={loadingClients ? "" : formik.values.clientId}
                  onChange={(event) => {
                    handleChangeClient(event, formik);
                  }}
                  onBlur={formik.handleBlur}
                >
                  {loadingClients ? (
                    <MenuItem>Loading...</MenuItem>
                  ) : (
                    allClients.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name} - {client.domain}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              {formik.touched.clientId && Boolean(formik.errors.clientId) && (
                <FormHelperText error>{formik.errors.clientId}</FormHelperText>
              )}
            </Container>

            <Container sx={styles.cardContainer}>
              <Typography sx={styles.title}>
                Appointments Schedule & Suggestions
              </Typography>
              <FormControl required sx={{ maxWidth: 250 }}>
                <InputLabel>Schedule</InputLabel>
                <Select
                  id="appointmentSchedule"
                  value={formik.values.appointmentSchedule}
                  label="Schedule"
                  onChange={formik.handleChange("appointmentSchedule")}
                  sx={{ textAlign: "left" }}
                >
                  {schedules.map((schedule, index) => (
                    <MenuItem key={index} value={schedule.value}>
                      {schedule.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.appointmentSchedule &&
                  Boolean(formik.errors.appointmentSchedule) && (
                    <FormHelperText error>
                      {formik.errors.appointmentSchedule}
                    </FormHelperText>
                  )}
              </FormControl>
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="appointment-suggestions-content"
                    id="appointment-suggestions-header"
                  >
                    <Typography sx={styles.subtitle}>Suggestions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldArray
                      name="appointmentSuggestions"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.appointmentSuggestions.map(
                            (obj, index) => (
                              <Box key={index} sx={styles.suggestionRow}>
                                <TextField
                                  label="Suggestion"
                                  name={`appointmentSuggestions[${index}].suggestion`}
                                  type="text"
                                  variant="outlined"
                                  required
                                  value={
                                    formik.values.appointmentSuggestions[index]
                                      .suggestion
                                  }
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={
                                    formik.touched.appointmentSuggestions?.[
                                      index
                                    ]?.suggestion &&
                                    Boolean(
                                      formik.errors.appointmentSuggestions?.[
                                        index
                                      ]?.suggestion
                                    )
                                  }
                                  helperText={
                                    formik.touched.appointmentSuggestions?.[
                                      index
                                    ]?.suggestion &&
                                    formik.errors.appointmentSuggestions?.[
                                      index
                                    ]?.suggestion
                                  }
                                  sx={{ flex: 2 }}
                                />
                                <FormControl
                                  required
                                  error={
                                    formik.touched.appointmentSuggestions?.[
                                      index
                                    ]?.types &&
                                    formik.errors.appointmentSuggestions?.[
                                      index
                                    ]?.types
                                  }
                                  sx={{ flex: 3 }}
                                >
                                  <InputLabel>Types</InputLabel>

                                  <Select
                                    multiple
                                    disabled={!formik.values.clientId}
                                    label="Type"
                                    name={`appointmentSuggestions[${index}].type`}
                                    value={
                                      formik.values.appointmentSuggestions[
                                        index
                                      ].type
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    renderValue={(selected) =>
                                      selected
                                        .map((value) => {
                                          const type = appointmentTypes.find(
                                            (apptType) => apptType === value
                                          );
                                          return type;
                                        })
                                        .join(", ")
                                    }
                                    error={
                                      formik.touched.appointmentSuggestions?.[
                                        index
                                      ]?.type &&
                                      Boolean(
                                        formik.errors.appointmentSuggestions?.[
                                          index
                                        ]?.type
                                      )
                                    }
                                    sx={{ textAlign: "left" }}
                                  >
                                    {appointmentTypes.map(
                                      (apptType, typeIndex) => (
                                        <MenuItem
                                          key={typeIndex}
                                          value={apptType}
                                        >
                                          <Checkbox
                                            checked={formik.values.appointmentSuggestions[
                                              index
                                            ].type.includes(apptType)}
                                          />
                                          {apptType}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  {formik.touched.appointmentSuggestions?.[
                                    index
                                  ]?.type &&
                                    formik.errors.appointmentSuggestions?.[
                                      index
                                    ]?.type && (
                                      <FormHelperText error>
                                        {
                                          formik.errors.appointmentSuggestions[
                                            index
                                          ].type
                                        }
                                      </FormHelperText>
                                    )}
                                  {!formik.values.clientId && (
                                    <FormHelperText error>
                                      Select a client to enable this field
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                <FormControl
                                  required
                                  error={
                                    formik.touched.appointmentSuggestions?.[
                                      index
                                    ]?.length &&
                                    formik.errors.appointmentSuggestions?.[
                                      index
                                    ]?.length
                                      ? true
                                      : false
                                  }
                                  sx={{ flex: 1 }}
                                >
                                  <InputLabel>Length</InputLabel>
                                  <Select
                                    label="Length"
                                    name={`appointmentSuggestions[${index}].length`}
                                    value={
                                      formik.values.appointmentSuggestions[
                                        index
                                      ].length
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                      formik.touched.appointmentSuggestions?.[
                                        index
                                      ]?.length &&
                                      Boolean(
                                        formik.errors.appointmentSuggestions?.[
                                          index
                                        ]?.length
                                      )
                                    }
                                    sx={{ textAlign: "left" }}
                                  >
                                    {lengths.map((length, lengthIndex) => (
                                      <MenuItem
                                        key={lengthIndex}
                                        value={length.value}
                                      >
                                        {length.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {formik.touched.appointmentSuggestions?.[
                                    index
                                  ]?.length &&
                                    formik.errors.appointmentSuggestions?.[
                                      index
                                    ]?.length && (
                                      <FormHelperText error>
                                        {
                                          formik.errors.appointmentSuggestions[
                                            index
                                          ].length
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                                <IconButton
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                  disabled={
                                    formik.values.appointmentSuggestions
                                      .length === 1
                                  }
                                >
                                  <ClearRounded />
                                </IconButton>
                              </Box>
                            )
                          )}
                          <Fab
                            size="medium"
                            aria-label="add suggestion"
                            onClick={() =>
                              arrayHelpers.push({
                                suggestion: "",
                                type: [],
                                length: "",
                              })
                            }
                            sx={styles.fab}
                          >
                            <Add />
                          </Fab>
                        </>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
            {!clinicProp && (
              <Container sx={styles.cardContainer}>
                <Typography sx={styles.title}>Credentials</Typography>
                <TextField
                  id="accuroOfficeName"
                  name="accuroOfficeName"
                  label="Accuro Office Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.accuroOfficeName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.accuroOfficeName &&
                    Boolean(formik.errors.accuroOfficeName)
                  }
                  helperText={
                    formik.touched.accuroOfficeName &&
                    formik.errors.accuroOfficeName
                  }
                />
                <Box sx={styles.rowBox}>
                  <TextField
                    id="accuroClientId"
                    name="accuroClientId"
                    label="Accuro Client ID"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    sx={{ flex: 1 }}
                    required
                    value={formik.values.accuroClientId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accuroClientId &&
                      Boolean(formik.errors.accuroClientId)
                    }
                    helperText={
                      formik.touched.accuroClientId &&
                      formik.errors.accuroClientId
                    }
                  />
                  <TextField
                    id="accuroClientSecret"
                    name="accuroClientSecret"
                    label="Accuro Client Secret"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    sx={{ flex: 1 }}
                    required
                    value={formik.values.accuroClientSecret}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accuroClientSecret &&
                      Boolean(formik.errors.accuroClientSecret)
                    }
                    helperText={
                      formik.touched.accuroClientSecret &&
                      formik.errors.accuroClientSecret
                    }
                  />
                </Box>
                <Box sx={styles.rowBox}>
                  <TextField
                    id="accuroSubscriptionKey"
                    name="accuroSubscriptionKey"
                    label="Accuro Subscription Key"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    sx={{ flex: 1 }}
                    required
                    value={formik.values.accuroSubscriptionKey}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accuroSubscriptionKey &&
                      Boolean(formik.errors.accuroSubscriptionKey)
                    }
                    helperText={
                      formik.touched.accuroSubscriptionKey &&
                      formik.errors.accuroSubscriptionKey
                    }
                  />
                  <TextField
                    id="accuroUUID"
                    name="accuroUUID"
                    label="Accuro UUID"
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    sx={{ flex: 1 }}
                    required
                    value={formik.values.accuroUUID}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accuroUUID &&
                      Boolean(formik.errors.accuroUUID)
                    }
                    helperText={
                      formik.touched.accuroUUID && formik.errors.accuroUUID
                    }
                  />
                </Box>
              </Container>
            )}
            <Box sx={styles.buttonsBox}>
              <Button
                variant="outlined"
                onClick={navigateToClientManagement}
                sx={styles.secondaryButton}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={styles.mainButton}>
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ClinicInfo;
