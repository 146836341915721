// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Material UI
import {
  Typography,
  Box,
  Toolbar,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { LockRounded, LogoutRounded, PersonRounded } from "@mui/icons-material";

// styles
import { styles } from "./styles";

// Resources
import { signOut } from "aws-amplify/auth";
import { terracotta } from "Constants/ColourConstants";

const NavigationBar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const handleTitleClick = () => {
    navigate("/");
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      window.location.reload();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleMenuClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordNavigate = () => {
    navigate("/ChangePassword");
    handleMenuClose();
  };

  return (
    <AppBar position="sticky" sx={styles.navBar}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            align="left"
            onClick={handleTitleClick}
            sx={windowWidth < 450 ? styles.mobileTitle : styles.title}
          >
            Medical Navigator
          </Typography>
        </Box>

        <div>
          <IconButton
            aria-owns={anchorEl ? "account-menu" : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
            size="large"
            sx={{ color: terracotta }}
          >
            <PersonRounded fontSize="inherit" />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {/* <MenuItem onClick={handleChangePasswordNavigate}>
              <ListItemIcon>
                <LockRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <Divider /> */}
            <MenuItem onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Sign Out</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
