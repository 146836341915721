// React
import React from "react";

// MUI
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

// Libraries
import { DateTime } from "luxon";

// Styles
import { styles } from "../styles";

const AvailableAppointmentsMobile = (props) => {
  const {
    availableAppointments,
    createAppointmentDetails,
    handleViewMoreModal,
    searchClinic,
    setSearchClinic,
  } = props;

  return (
    <Box sx={styles.cardsMainBox}>
      <Box sx={{ ...styles.textFieldBox, ml: 2 }}>
        <TextField
          id="outlined-basic"
          label="Search Clinics"
          variant="outlined"
          size="small"
          sx={{
            ...styles.textField,
            width: "50%",
          }}
          value={searchClinic}
          onChange={(e) => setSearchClinic(e.target.value)}
        />
      </Box>
      <Box sx={styles.mobileCardsBox}>
        {availableAppointments.length === 0 && (
          <Typography>No Appointments Available</Typography>
        )}
        <Grid spacing={6} container justifyContent="center">
          {availableAppointments.map((appointment, index) => (
            <Card key={index} sx={styles.mobileCards}>
              <CardContent>
                {/* Clinic Details */}
                <Box sx={styles.cardClinicBox}>
                  <Typography sx={styles.mobileBoldText}>
                    {appointment.Clinic.name}
                  </Typography>
                  <Typography sx={styles.mobileSubText}>
                    {appointment.Clinic.location}
                  </Typography>
                </Box>

                {/* Appointment Details */}
                <Box sx={{ my: 1 }}>
                  <Typography sx={styles.mobileSubText}>
                    Earliest Appointment
                  </Typography>
                  <Typography sx={styles.mobileMainText}>
                    {DateTime.fromISO(
                      appointment.availableSlots[0].date
                    ).toFormat("MMMM dd, yyyy")}
                  </Typography>
                  <Typography sx={styles.mobileMainText}>
                    {appointment.availableSlots[0].startTime}
                  </Typography>
                </Box>
              </CardContent>

              {/* Buttons */}
              <CardActions>
                <Box
                  sx={{
                    ...styles.bookViewMoreBox,
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={styles.bookButton}
                    color="primary"
                    onClick={() => createAppointmentDetails(appointment)}
                  >
                    Book
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={appointment.availableSlots.length === 1}
                    sx={styles.viewMoreButton}
                    onClick={() => handleViewMoreModal(appointment)}
                  >
                    View More
                  </Button>
                </Box>
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AvailableAppointmentsMobile;
