// React
import { useEffect } from "react";

// Amplify
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserGroup,
  setUserEmail,
  setUserGroup,
} from "../../../Store/AuthSlice";

// Components
import PatientPortal from "../../Users/Patient/PatientPortal";
import AdminPortal from "../../Users/Admin/AdminPortal";
import AutoSignOut from "../AutoSignOut";

const SignedIn = () => {
  const userGroup = useSelector(selectUserGroup);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCognitoUser = async () => {
      const cognitoUser = await fetchAuthSession();
      const userEmail = cognitoUser.tokens.idToken.payload.email;
      const userGroup =
        cognitoUser.tokens.accessToken.payload["cognito:groups"];

      dispatch(setUserEmail(userEmail));
      dispatch(setUserGroup(userGroup[0]));
    };
    fetchCognitoUser();
  }, []);

  const RenderPortal = () => {
    switch (userGroup) {
      case "PATIENT":
        return <PatientPortal />;
      case "ADMIN":
        return <AdminPortal />;
      default:
        return null;
    }
  };

  return (
    <>
      <AutoSignOut />
      <RenderPortal />
    </>
  );
};

export default SignedIn;
