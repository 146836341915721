// Yup Validation
import * as yup from "yup";

const emailValidation = yup
  .string("Enter email")
  .email("Enter a valid email address")
  .required("Email is required");

const passwordValidation = yup
  .string("Enter password")
  .min(8, "Password must be at least 8 characters")
  .required("Password is required")
  .test("password-strength", "", (value) => {
    return (
      /[a-z]/.test(value) && // lowercase letters
      /[A-Z]/.test(value) && // uppercase letters
      /[0-9!@#$%^&*(),.?":{}|<>]/.test(value) // numbers and special characters
    );
  });

// ------------------ SIGN UP ------------------

export const signupValidationSchema = yup.object({
  email: emailValidation,
  password: passwordValidation,
  confirmPassword: yup
    .string("Enter confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions to proceed"),
});

// ------------------ SIGN IN ------------------

export const signinValidationSchema = yup.object({
  email: emailValidation,
  password: yup.string("Enter password").required("Password is required"),
});

// -------------- RESET PASSWORD ---------------

export const emailValidationSchema = yup.object({
  email: emailValidation,
});

export const passwordValidationSchema = yup.object({
  code: yup
    .string("Enter verification code")
    .length(6, "Verification code must be 6 digits")
    .required("Verification code is required"),
  password: passwordValidation,
  confirmPassword: yup
    .string("Enter confirm password")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});
