import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";

// MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TimeIcon from "@mui/icons-material/AccessTime";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import ClinicIcon from "@mui/icons-material/Business";
import LocationIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { mdiCloseCircleOutline } from "@mdi/js";

// Resources
import { bookAppointment } from "API/Resource/appointment";
import { fetchAppointments } from "../Common/fetchAppointments";

// Styles
import { styles } from "./styles";
import { white } from "Constants/ColourConstants";

const BookAppointmentModal = ({ closeModal, appointment }) => {
  const [openSuccessScreen, setOpenSuccessScreen] = useState(false);
  const [openAppointmentUnavailable, setOpenAppointmentUnavailable] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const patient = useSelector(selectPatient);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleConfirm = async () => {
    setLoading(true);
    try {
      // Filter out unnecessary fields from the appointment object
      const filteredAppointment = { ...appointment };
      delete filteredAppointment.clinicName;
      delete filteredAppointment.physician;
      delete filteredAppointment.address;
      filteredAppointment.date = new Date(appointment.date)
        .toISOString()
        .split("T")[0];

      await bookAppointment(filteredAppointment);
      setOpenSuccessScreen(true);
    } catch (error) {
      const errorMessages = {
        APPOINTMENT_SLOT_UNAVAILABLE:
          "This appointment slot is no longer available.",
        ACTIVE_APPOINTMENT_EXISTS:
          "You currently have an active appointment at this clinic.",
        APPOINTMENT_TIME_PASSED: "The selected appointment time has passed.",
      };
      const defaultErrorMessage =
        "Oops! Something went wrong while booking your appointment.";

      const errorMessage = errorMessages[error.message] || defaultErrorMessage;
      setErrorMessage(errorMessage);
      setOpenAppointmentUnavailable(true);
    } finally {
      setLoading(false);
    }
  };

  const onAlreadyBooked = async () => {
    setLoading(true);
    const availableSlots = await fetchAppointments(patient, appointment.type);
    const data = {
      reasonForVisit: appointment.type,
      additionalInfo: appointment.notes,
      availableSlots,
    };
    navigate("/Appointments", { state: data });
    closeModal();
    setLoading(false);
  };

  return (
    <Box sx={styles.overlay}>
      <Box sx={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        {!openSuccessScreen && !openAppointmentUnavailable ? (
          <>
            {/* Main Booking Screen */}
            <Typography sx={styles.title}>CONFIRM BOOKING</Typography>

            <Box sx={styles.appointmentDetails}>
              <Box sx={styles.detailItem}>
                <PersonIcon sx={styles.icon} />
                <Typography>
                  {patient.firstName} {patient.lastName}
                </Typography>
              </Box>
              <Box sx={styles.detailItem}>
                <TimeIcon sx={styles.icon} />
                <Typography>{appointment.startTime}</Typography>
              </Box>
              <Box sx={styles.detailItem}>
                <CalendarIcon sx={styles.icon} />
                <Typography>{appointment.date}</Typography>
              </Box>
              <Box sx={styles.detailItem}>
                <ClinicIcon sx={styles.icon} />
                <Typography>{appointment.clinicName}</Typography>
              </Box>
              <Box sx={styles.detailItem}>
                <LocationIcon sx={styles.icon} />
                <Typography>{appointment.address}</Typography>
              </Box>
              <Box sx={styles.detailItem}>
                <StarIcon sx={styles.icon} />
                <Typography>{appointment.type}</Typography>
              </Box>
            </Box>

            <Box sx={styles.actionButtons}>
              <Button
                onClick={closeModal}
                variant="outlined"
                disabled={loading}
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                variant="contained"
                disabled={loading}
                sx={styles.confirmButton}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={"14px"}
                      sx={{ color: white, marginRight: "8px" }}
                    />
                    Booking
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>
            </Box>
          </>
        ) : openSuccessScreen ? (
          <>
            {/* Success Screen */}
            <Box sx={styles.successContainer}>
              <Box sx={styles.successIcon}>
                <Icon path={mdiCheckCircleOutline} size={6} color="#2f5230" />
              </Box>
              <Typography sx={styles.successMessage}>
                Appointment Successfully Booked!
              </Typography>
              <Button
                onClick={() => {
                  navigate("/");
                }}
                variant="contained"
                sx={styles.okButton}
              >
                OK
              </Button>
            </Box>
          </>
        ) : (
          <>
            {/* Appointment Unavailable Screen */}
            <Box sx={styles.successContainer}>
              <Box sx={styles.successIcon}>
                <Icon path={mdiCloseCircleOutline} size={6} color="#cb6843" />{" "}
              </Box>
              <Typography sx={styles.successMessage}>
                {errorMessage}
                <br />
                Please click OK to refresh the available appointments.
              </Typography>
              <Button
                onClick={onAlreadyBooked}
                variant="contained"
                sx={styles.okButton}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={"14px"}
                      sx={{ color: white, marginRight: "8px" }}
                    />
                    Refreshing
                  </>
                ) : (
                  "OK"
                )}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BookAppointmentModal;
