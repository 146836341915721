// Yup
import { string, object, array } from "yup";

// Phone validation
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const clinicValidationSchema = object({
  name: string("Enter the clinic's name").required("Clinic name is required"),
  address: string("Enter the clinic's address").required(
    "Clinic address is required"
  ),
  city: string("Enter the clinic's city").required("Clinic city is required"),
  postalCode: string("Etner the clinic's postal code")
    .matches(
      /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
      "Postal code must be in the format A1A 1A1"
    )
    .required("Clinic postal code is required"),
  province: string().required("Clinic province is required"),
  phone: string()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, "CA");
      return phoneNumber ? phoneNumber.isValid() : false;
    })
    .required("Clinic phone number is required"),
  altPhone: string()
    .nullable()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (!value) return true;
      const phoneNumber = parsePhoneNumberFromString(value, "CA");
      return phoneNumber ? phoneNumber.isValid() : false;
    }),
  appointmentSchedule: string().required(),
  appointmentSuggestions: array()
    .of(
      object().shape({
        suggestion: string().required("Suggestion is required"),
        type: array()
          .min(1, "At least one type is required")
          .required("Types are required"),
        length: string().required("Length is required"),
      })
    )
    .min(1, "At least one suggestion is required")
    .required("Suggestions are required"),
  accuroClientId: string("Enter accuro client ID").required(
    "Accuro client ID is required"
  ),
  accuroClientSecret: string("Enter accuro client secret token").required(
    "Accuro client secret token is required"
  ),
  accuroSubscriptionKey: string("Enter accuro subscription key").required(
    "Accuro subscription key is required"
  ),
  accuroUUID: string("Enter accuro UUID").required("Accuro UUID is required"),
  accuroOfficeName: string("Enter accuro office name").required(
    "Accuro office name is required"
  ),
  clientId: string("Select a client").required("Client is required"),
});
