// Material UI
import { Typography, Box } from "@mui/material";
import { Copyright } from "@mui/icons-material";

// Styles
import { styles } from "./styles";
import { APP_VERSION } from "config";

export const AuthFooter = ({ showCopyright = true }) => {
  const year = new Date().getFullYear();

  return (
    <Box sx={styles.footerBox}>
      <address style={styles.techSupport} align={"center"}>
        For technical support, email us at{" "}
        <a href="mailto:techsupport@medinav.ca" style={styles.email}>
          techsupport@medinav.ca
        </a>
      </address>
      {showCopyright && (
        <Box sx={styles.copyRightBox} gap={0.5}>
          <Typography display="block">Version: {APP_VERSION}</Typography>
        </Box>
      )}
    </Box>
  );
};
