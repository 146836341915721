// React
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Material UI
import { Box } from "@mui/material";

// Components
import AdminHome from "../AdminHome";
import ClinicManagement from "../ClinicManagement";
import NavigationBar from "../Navigation/NavigationBar";
import NavigationDrawer from "../Navigation/NavigationDrawer";
import { DrawerHeader } from "../Navigation/NavigationDrawer/styles";
import ClinicInfo from "../ClinicInfo";
import ClientInfo from "../ClientInfo";
import ClientManagement from "../ClientManagement";

// Styles
import { styles } from "./styles";

const AdminPortal = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ignoreClickAway, setIgnoreClickAway] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setIgnoreClickAway(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={styles.portalBox}>
      <NavigationBar open={drawerOpen} handleOpen={handleDrawerOpen} />
      <NavigationDrawer
        open={drawerOpen}
        handleClose={handleDrawerClose}
        ignoreClickAway={ignoreClickAway}
        setIgnoreClickAway={setIgnoreClickAway}
      />
      <Box component="main" sx={styles.mainComponent}>
        <DrawerHeader />
        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/ClinicManagement" element={<ClinicManagement />} />
          <Route path="/AddClinic" element={<ClinicInfo />} />
          <Route path="/EditClinic" element={<ClinicInfo />} />
          <Route path="/ClientManagement" element={<ClientManagement />} />
          <Route path="/AddClient" element={<ClientInfo />} />
          <Route path="/EditClient" element={<ClientInfo />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminPortal;
