// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectPatient } from "Store/PatientSlice";
import { selectUserEmail } from "Store/AuthSlice";

// Resources
import { provinces } from "Constants/ProvinceConstants";

// MUI
import {
  Card,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";

// Formik
import { useFormik } from "formik";

// Luxon
import { DateTime } from "luxon";

// Styles
import { styles } from "./styles";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Components
import ConfirmEditModal from "./ConfirmEditModal";

// Validation
import { patientValidationSchema } from "Validation/PatientValidation";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// Components
import DuplicatePHINModal from "./DuplicatePHINModal";
import ActivatePatientModal from "./ActivatePatientModal";
import { getPatient } from "API/Resource/patient";

const PatientEdit = () => {
  const hiddenPatient = useSelector(selectPatient);
  const userEmail = useSelector(selectUserEmail);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [openActivatedDialog, setOpenActivatedDialog] = useState(false);
  const [openDuplicatePHINDialog, setOpenDuplicatePHINDialog] = useState(false);
  const [patient, setPatient] = useState(null);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchPatient = async () => {
      setFetching(true);
      if (hiddenPatient?.id) {
        const patient = await getPatient(hiddenPatient.id);
        setPatient(patient);
      }
      setFetching(false);
    };
    fetchPatient();
  }, [hiddenPatient]);

  const formik = useFormik({
    initialValues: {
      id: patient?.id,
      firstName: patient?.firstName || "",
      lastName: patient?.lastName || "",
      dob: patient?.dob ? DateTime.fromISO(patient?.dob) : null,
      address: patient?.address || "",
      city: patient?.city || "",
      postalCode: patient?.postalCode || "",
      province: patient?.province || "MB",
      primaryPhone: patient?.primaryPhone || "",
      secondaryPhone: patient?.secondaryPhone || "",
      email: patient?.email || "",
      healthCardNumber: patient?.healthCardNumber || "",
      registrationNumber: patient?.registrationNumber || "",
      healthCardProvince: patient?.healthCardProvince || "MB",
      notes: patient?.notes || "",
      userEmail: userEmail,
    },
    enableReinitialize: true,
    validationSchema: patientValidationSchema,
    onSubmit: async (values) => {
      setConfirmModal(true);
      const formattedValues = {
        ...values,
        dob: values.dob ? values.dob.toFormat("yyyy-MM-dd") : null,
        primaryPhone: parsePhoneNumberFromString(
          values.primaryPhone,
          "CA"
        )?.formatNational(),
        secondaryPhone: parsePhoneNumberFromString(
          values.secondaryPhone,
          "CA"
        )?.formatNational(),
      };
      setPatientValues(formattedValues);
    },
  });
  const [patientValues, setPatientValues] = useState(formik.initialValues);

  return (
    <Box sx={styles.background}>
      <ActivatePatientModal
        open={openActivatedDialog}
        onClose={() => {
          setOpenActivatedDialog(false);
          navigate("/");
        }}
      />
      <DuplicatePHINModal
        open={openDuplicatePHINDialog}
        onClose={() => setOpenDuplicatePHINDialog(false)}
      />
      <Box sx={styles.titleBox}>
        <Grid container sx={styles.headerBox} spacing={1}>
          <Grid size={{ xs: 4, sm: 3, md: 2 }}>
            <Button
              variant="contained"
              sx={styles.backButton}
              onClick={() => {
                navigate("/");
              }}
            >
              <ArrowBackIcon sx={styles.backArrow} />
              <Typography sx={styles.backButtonText}>BACK</Typography>
            </Button>
          </Grid>
          {!patient?.id && (
            <Typography sx={styles.pageTitle}>Patient Registration</Typography>
          )}
          {patient?.id && (
            <Typography sx={styles.pageTitle}> Patient Edit</Typography>
          )}
        </Grid>
      </Box>
      {fetching ? (
        <CircularProgress sx={styles.loading} />
      ) : (
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Card sx={styles.cardBackground}>
            <Typography sx={styles.cardTitle}>DEMOGRAPHICS</Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="firstName"
                  label="First Name *"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    htmlInput: { maxLength: 30 },
                  }}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="lastName"
                  label="Last Name *"
                  value={formik.values?.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    htmlInput: { maxLength: 75 },
                  }}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <DatePicker
                  inputFormat="MM/dd/yyyy"
                  label="Date of Birth *"
                  views={["year", "month", "day"]}
                  id="dob"
                  disableFuture
                  value={formik.values?.dob}
                  onChange={(value) => {
                    formik.setFieldValue("dob", value);
                  }}
                  onBlur={formik.handleBlur}
                  sx={styles.datePicker}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: formik.touched.dob && Boolean(formik.errors.dob),
                      helperText: formik.touched.dob && formik.errors.dob,
                      sx: styles.inputTextField,
                    },
                  }}
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="email"
                  label="Email *"
                  value={formik.values?.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    htmlInput: { maxLength: 75 },
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="primaryPhone"
                  label="Phone Number *"
                  value={formik.values?.primaryPhone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.primaryPhone &&
                    Boolean(formik.errors.primaryPhone)
                  }
                  helperText={
                    formik.touched.primaryPhone && formik.errors.primaryPhone
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid
                size={{ xs: 12, md: 6 }}
                sx={{ paddingBottom: { xs: "5%", md: "1%" } }}
              >
                <TextField
                  variant="standard"
                  id="secondaryPhone"
                  label="Secondary Phone Number"
                  value={formik.values?.secondaryPhone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.secondaryPhone &&
                    Boolean(formik.errors.secondaryPhone)
                  }
                  helperText={
                    formik.touched.secondaryPhone &&
                    formik.errors.secondaryPhone
                      ? formik.errors.secondaryPhone
                      : "Text notifications will only be sent to the primary phone number"
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="address"
                  label="Address *"
                  value={formik.values?.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  slotProps={{
                    htmlInput: { maxLength: 75 },
                  }}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="city"
                  label="City *"
                  value={formik.values?.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  sx={styles.inputTextField}
                  slotProps={{
                    htmlInput: { maxLength: 30 },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="postalCode"
                  label="Postal Code *"
                  value={formik.values?.postalCode}
                  onChange={(event) => {
                    formik.setFieldValue(
                      "postalCode",
                      event.target.value.toUpperCase()
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  variant="standard"
                  id="province"
                  name="province"
                  label="Province *"
                  select
                  value={formik.values?.province}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.province && Boolean(formik.errors.province)
                  }
                  helperText={formik.touched.province && formik.errors.province}
                  sx={styles.inputTextField}
                >
                  {provinces
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((location, index) => (
                      <MenuItem key={index} value={location.code}>
                        {location.code} - {location.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </Card>
          <Card sx={styles.cardBackground}>
            <Typography sx={styles.cardTitle}>
              HEALTH CARD INFORMATION
            </Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 4 }}>
                <TextField
                  variant="standard"
                  id="healthCardProvince"
                  name="healthCardProvince"
                  label="Health Card Province *"
                  select
                  value={formik.values?.healthCardProvince}
                  onChange={(event) => {
                    const selectedProvince = event.target.value;
                    if (selectedProvince !== "MB") {
                      formik.values.registrationNumber = "";
                    }
                    formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.healthCardProvince &&
                    Boolean(formik.errors.healthCardProvince)
                  }
                  helperText={
                    formik.touched.healthCardProvince &&
                    formik.errors.healthCardProvince
                  }
                  sx={styles.inputTextField}
                >
                  {provinces
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((location, index) => (
                      <MenuItem key={index} value={location.code}>
                        {location.code} - {location.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <TextField
                  variant="standard"
                  id="healthCardNumber"
                  label="Health Card Number *"
                  value={formik.values?.healthCardNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.healthCardNumber &&
                    Boolean(formik.errors.healthCardNumber)
                  }
                  helperText={
                    formik.touched.healthCardNumber &&
                    formik.errors.healthCardNumber
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <TextField
                  variant="standard"
                  id="registrationNumber"
                  label="Registration Number"
                  required={formik.values.healthCardProvince === "MB"}
                  value={formik.values?.registrationNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.registrationNumber &&
                    Boolean(formik.errors.registrationNumber)
                  }
                  helperText={
                    formik.touched.registrationNumber &&
                    formik.errors.registrationNumber
                  }
                  sx={styles.inputTextField}
                />
              </Grid>
            </Grid>
          </Card>
          {/* <Card sx={styles.cardBackground}>
          <Typography sx={styles.cardTitle}>ADDITIONAL INFORMATION</Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                variant="standard"
                id="pharmacy"
                label="Preffered Pharmacy"
                value={formik.values?.pharmacy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.pharmacy && Boolean(formik.errors.pharmacy)
                }
                helperText={formik.touched.pharmacy && formik.errors.pharmacy}
                sx={styles.inputTextField}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                variant="standard"
                id="familyDoctor"
                label="Family Doctor"
                value={formik.values?.familyDoctor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.familyDoctor &&
                  Boolean(formik.errors.familyDoctor)
                }
                helperText={
                  formik.touched.familyDoctor && formik.errors.familyDoctor
                }
                sx={styles.inputTextField}
              />
            </Grid>
            <Grid size={12}> 
              <TextField
                variant="standard"
                id="allergies"
                label="Allergies"
                multiline
                value={formik.values?.allergies}
                onChange={formik.handleChange}
                sx={styles.multiLineTextField}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                variant="standard"
                id="notes"
                label="Notes (Include any relevant medications and medical history)"
                multiline
                value={formik.values?.notes}
                onChange={formik.handleChange}
                sx={styles.multiLineTextField}
              />
            </Grid>
          </Grid>
        </Card> */}
          <Box sx={styles.buttonBox}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 0, md: 8 }} />
              <Grid size={{ xs: 12, md: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={styles.cancelButton}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid size={{ xs: 12, md: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={styles.submitButton}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <ConfirmEditModal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        patientValues={patientValues}
        setOpenActivatedDialog={setOpenActivatedDialog}
        setOpenDuplicatePHINDialog={setOpenDuplicatePHINDialog}
      />
    </Box>
  );
};

export default PatientEdit;
