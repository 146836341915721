// React
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Material UI
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grow,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  MenuRounded,
  MenuOpenRounded,
  DashboardRounded,
  LocationCityRounded,
  DomainAddRounded,
  AddBusinessRounded,
} from "@mui/icons-material";

// Components
import { styles, StyledDrawer, DrawerHeader } from "./styles";

const NavigationDrawer = ({
  open,
  handleClose,
  ignoreClickAway,
  setIgnoreClickAway,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleClickAway = () => {
    if (ignoreClickAway) {
      setIgnoreClickAway(false);
      return;
    }
    handleClose();
  };

  const DrawerListItem = ({ label, path, icon }) => {
    const location = useLocation();

    return (
      <ListItem
        key={label}
        disablePadding
        sx={{ display: "block" }}
        onClick={() => handleNavigation(path)}
      >
        <ListItemButton sx={styles.listItemButton(open)}>
          <ListItemIcon
            sx={styles.listItemIcon(open, location.pathname === path)}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    );
  };

  const DrawerDivider = ({ title }) => {
    return (
      <Divider aria-hidden="true" textAlign="left">
        {open && (
          <Grow in={open}>
            <Typography sx={{ fontSize: 13, textAlign: "left" }}>
              {title}
            </Typography>
          </Grow>
        )}
      </Divider>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledDrawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            boxShadow: "2px 0px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            {theme.direction === "rtl" ? <MenuRounded /> : <MenuOpenRounded />}
          </IconButton>
        </DrawerHeader>
        <List>
          <DrawerListItem
            label="Home Page"
            path="/"
            icon={<DashboardRounded />}
          />
        </List>
        <DrawerDivider title="Clinics" />
        <List>
          <DrawerListItem
            label="Client Management"
            path="/ClientManagement"
            icon={<LocationCityRounded />}
          />
          <DrawerListItem
            label="Add Client"
            path="/AddClient"
            icon={<DomainAddRounded />}
          />
          <DrawerListItem
            label="Add Clinic"
            path="/AddClinic"
            icon={<AddBusinessRounded />}
          />
        </List>
      </StyledDrawer>
    </ClickAwayListener>
  );
};

export default NavigationDrawer;
