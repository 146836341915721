import { terracotta, emeraldGreen } from "Constants/ColourConstants";

export const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1300,
  },
  modalContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    width: "30%",
    minWidth: "315px",
    padding: 2,
    position: "relative",
  },
  header: {
    marginBottom: "24px",
    textAlign: "left",
  },
  title: {
    color: emeraldGreen,
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "16px",
    textAlign: "left",
  },
  appointmentDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginLeft: ".5rem",
    marginBottom: "24px",
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
  },
  icon: {
    color: emeraldGreen,
    marginRight: "8px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginTop: "16px",
  },
  cancelButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "150px",
  },
  confirmButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "150px",
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
  },
  successIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "18px",
  },
  successMessage: {
    color: "black",
    fontSize: "18px",
    fontWeight: "500",
    marginBottom: "24px",
    textAlign: "center",
  },
  okButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "150px",
  },
};
