// React
import { useState, useEffect } from "react";

// Material UI
import { Box, Button, FormHelperText } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

// Styles
import { styles } from "./styles";

export const OTP = ({
  setCode,
  resendCode,
  values,
  handleChange,
  errors,
  touched,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClearCode = () => {
    setCode("code", "");
  };

  const validateChar = (value, index) => {
    const isNumeric = /^[0-9]$/.test(value);
    return isNumeric;
  };

  return (
    <Box sx={styles.otpBox}>
      <Box>
        <MuiOtpInput
          value={values.code}
          onChange={handleChange}
          length={6}
          autoFocus
          validateChar={validateChar}
          TextFieldsProps={{ size: isMobile ? "small" : "medium" }}
          display={"flex"}
          flexDirection={"row"}
          gap={isMobile ? 1 : 2}
          maxWidth={isMobile ? 325 : 450}
          sx={styles.otpInput}
        />
        {touched.code && Boolean(errors.code) && (
          <FormHelperText
            error
            id="otp-error-text"
            sx={{ textAlign: "center" }}
          >
            {errors.code}
          </FormHelperText>
        )}
      </Box>
      <Box sx={styles.otpButtonsBox}>
        <Button
          variant="outlined"
          onClick={handleClearCode}
          sx={styles.otpButtons}
        >
          {isMobile ? "Clear" : "Clear Code"}
        </Button>
        <Button variant="outlined" onClick={resendCode} sx={styles.otpButtons}>
          {isMobile ? "Resend" : "Resend Code"}
        </Button>
      </Box>
    </Box>
  );
};
