// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Resources
import {
  getAllClinics,
  updateClinic,
  updateAccuroInfo,
  getActivateURL,
} from "API/Resource/clinic";

// Material UI
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  AddRounded,
  BlockRounded,
  CachedRounded,
  CancelRounded,
  ModeEditRounded,
  SearchRounded,
} from "@mui/icons-material";
import { MuiOtpInput } from "mui-one-time-password-input";

// Formik
import { useFormik } from "formik";
import * as yup from "yup";

// Styles
import { styles } from "./styles";

// Constants
import { emeraldGreen, terracotta } from "Constants/ColourConstants";
import { provinces } from "Constants/ProvinceConstants";

const ClinicManagement = (props) => {
  const { client } = props;
  const [activeClinics, setActiveClinics] = useState([]);
  const [inactiveClinics, setInactiveClinics] = useState([]);
  const [filteredClinics, setFilteredClinics] = useState(activeClinics);
  const [clinicSearch, setClinicSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmDisableOpen, setConfirmDisableOpen] = useState(false);
  const [authCodeOpen, setAuthCodeOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const provinceLookup = provinces.reduce((acc, province) => {
    acc[province.name] = province.code;
    return acc;
  }, {});

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "postalCode",
      headerName: "Postal Code",
      width: 100,
    },
    {
      field: "city",
      headerName: "City",
      width: 135,
    },
    {
      field: "province",
      headerName: "Province",
      width: 75,
      renderCell: (params) => provinceLookup[params.value] || params.value,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 130,
    },
    {
      field: "altPhone",
      headerName: "Alt. Phone",
      width: 130,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <Box sx={styles.actionsBox}>
            <Tooltip title="Edit clinic" placement="top">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  navigate("/EditClinic", { state: { clinic: params.row } })
                }
                sx={{ my: "auto" }}
              >
                <ModeEditRounded />
              </IconButton>
            </Tooltip>
            {tabIndex === 0 ? (
              <Tooltip title="Disable clinic" placement="top">
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={() => handleConfirmDisableOpen(params.row)}
                  sx={{ my: "auto" }}
                >
                  <BlockRounded />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Activate clinic" placement="top">
                <IconButton
                  aria-label="activate"
                  color="success"
                  onClick={async () => {
                    const activateURL = await getActivateURL(params.row.id);
                    window.open(activateURL, "_blank");
                    handleAuthCodeOpen(params.row);
                  }}
                  sx={{ my: "auto" }}
                >
                  <CachedRounded />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let isCanceled = false;
    handleFetchClinics(isCanceled);

    return () => {
      isCanceled = true;
    };
  }, []);

  useEffect(() => {
    const clinicsToFilter = tabIndex === 0 ? activeClinics : inactiveClinics;

    const clientClinics = client
      ? clinicsToFilter.filter((clinic) => clinic.clientId === client.id)
      : clinicsToFilter;

    const filtered = clientClinics.filter((clinic) =>
      clinic.name.toLowerCase().includes(clinicSearch.toLowerCase())
    );
    setFilteredClinics(filtered);
  }, [clinicSearch, tabIndex, activeClinics, inactiveClinics, client]);

  const handleFetchClinics = async (isCanceled = false) => {
    setLoading(true);

    try {
      const response = await getAllClinics(false);
      if (!isCanceled) {
        setActiveClinics(response.filter((clinic) => clinic.isActive === true));
        setInactiveClinics(
          response.filter((clinic) => clinic.isActive === false)
        );
      }
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const handleConfirmDisableOpen = (row) => {
    setSelectedRow(row);
    setConfirmDisableOpen(true);
  };

  const handleConfirmDisableClose = () => {
    setSelectedRow(null);
    setConfirmDisableOpen(false);
  };

  const handleAuthCodeOpen = (row) => {
    setSelectedRow(row);
    setAuthCodeOpen(true);
  };

  const handleAuthCodeClose = () => {
    setSelectedRow(null);
    setAuthCodeOpen(false);
  };

  const handleDisableClinic = async () => {
    try {
      await updateClinic({ id: selectedRow.id, isActive: false });
      dispatch(
        openSnackbar({
          message: "Clinic disabled successfully!",
          severity: "success",
        })
      );
      handleConfirmDisableClose();
      handleFetchClinics();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleGenerateToken = async (id, accuroAuthCode) => {
    try {
      await updateAccuroInfo(id, accuroAuthCode);
      dispatch(
        openSnackbar({
          message: "Clinic activated successfully!",
          severity: "success",
        })
      );
      handleAuthCodeClose();
      setTabIndex(0);
      handleFetchClinics();
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const AuthCodeDialog = ({ id }) => {
    const [loading, setLoading] = useState(false);
    const validationSchema = yup.object({
      code: yup
        .string("Enter authentication code")
        .length(6, "Authentication code must be 6 digits")
        .required("Authentication code is required"),
    });
    const formik = useFormik({
      initialValues: {
        code: "",
      },
      validationSchema,
      onSubmit: (values) => {
        setLoading(true);
        handleGenerateToken(id, values.code);
      },
    });

    return (
      <Dialog
        component="form"
        open={authCodeOpen}
        onClose={handleAuthCodeClose}
        onSubmit={formik.handleSubmit}
        aria-labelledby="generate-token-dialog"
      >
        <DialogTitle color={emeraldGreen} sx={{ textTransform: "uppercase" }}>
          Activate Clinic
        </DialogTitle>
        <DialogContent sx={styles.authCodeContent}>
          <DialogContentText>
            To proceed, enter the Authentication Code below.{" "}
          </DialogContentText>
          <Box sx={styles.authCodeBox}>
            <Box>
              <MuiOtpInput
                value={formik.values.code}
                onChange={formik.handleChange("code")}
                length={6}
                autoFocus
                display={"flex"}
                flexDirection={"row"}
                gap={2}
                width={450}
              />
              {formik.touched.code && Boolean(formik.errors.code) && (
                <FormHelperText
                  error
                  id="auth-code-error-text"
                  sx={{ textAlign: "center" }}
                >
                  {formik.errors.code}
                </FormHelperText>
              )}
            </Box>
            <Button
              variant="outlined"
              onClick={() => formik.setFieldValue("code", "")}
              sx={styles.clearAuthCodeButton}
            >
              Clear Code
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleAuthCodeClose}
            sx={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={styles.submitButton}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="23px" color="white" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ConfirmDisableDialog = () => {
    const [loading, setLoading] = useState(false);
    return (
      <Dialog
        open={confirmDisableOpen}
        onClose={() => handleConfirmDisableClose}
        aria-labelledby="confirm-disable-dialog"
      >
        <DialogTitle color={emeraldGreen} sx={{ textTransform: "uppercase" }}>
          Disable Clinic
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable clinic{" "}
            <strong>{selectedRow?.name}</strong>? <br />
            This action can be reverted under the <strong>Inactive</strong> tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleConfirmDisableClose}
            sx={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setLoading(true);
              handleDisableClinic();
            }}
            autoFocus
            sx={styles.confirmButton}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="23px" color="white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <ConfirmDisableDialog />
      <AuthCodeDialog id={selectedRow?.id} />

      <Box sx={styles.titleBox}>
        <Typography sx={styles.title}>Clinic Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddRounded />}
          sx={styles.addButton}
          onClick={() => navigate("/AddClinic")}
        >
          Add Clinic
        </Button>
      </Box>

      <Tabs
        value={tabIndex}
        onChange={(event, newTab) => setTabIndex(newTab)}
        aria-label="active-inactive-clincs"
        variant="fullWidth"
        TabIndicatorProps={{ sx: { backgroundColor: terracotta } }}
        sx={{ px: 20, pb: 4 }}
      >
        <Tab label="Active" sx={{ "&.Mui-selected": { color: terracotta } }} />
        <Tab
          label="Inactive"
          sx={{ "&.Mui-selected": { color: terracotta } }}
        />
      </Tabs>
      <Container sx={styles.contentBox}>
        <TextField
          name="searchClinic"
          placeholder="Search Clinic"
          type="text"
          variant="standard"
          value={clinicSearch}
          onChange={(e) => setClinicSearch(e.target.value)}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clear search bar"
                    onClick={() => setClinicSearch("")}
                    onMouseDown={() => setClinicSearch("")}
                    edge="end"
                    size="small"
                  >
                    <CancelRounded fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          sx={{ width: 250, ml: "auto" }}
        />
        <DataGrid
          rows={filteredClinics}
          columns={columns}
          rowHeight={60}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
          loading={loading}
          sx={styles.datagrid}
        />
      </Container>
    </>
  );
};

export default ClinicManagement;
