import {
  offWhite,
  grey,
  emeraldGreen,
  terracotta,
} from "Constants/ColourConstants";

export const styles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "darkgray",
      },
      "&.Mui-focused fieldset": {
        borderColor: terracotta,
      },
    },
    "& .MuiInputLabel-root": {
      color: "gray",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: terracotta,
    },
  },
  textFieldBox: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cancelAppointmentBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    gap: 1,
    textAlign: "center",
  },
  cancelButton: {
    borderRadius: "50px",
    padding: "8px 16px",
    minWidth: "120px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  mobileCardsBox: {
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  cardBoldText: {
    fontWeight: "bold",
    color: emeraldGreen,
  },
  cardMainText: {
    fontSize: 18,
    fontWeight: "bold",
    color: emeraldGreen,
  },
  cardSubText: {
    fontSize: 14,
    color: grey,
  },
  card: {
    paddingBottom: "20px",
    width: "300px",
    height: "350px",
    position: "relative",
    borderRadius: "0.7rem",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  chip: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "white",
    fontWeight: "bold",
    px: 1.5,
    zIndex: 1,
  },
  box: {
    width: "100%",
    height: "auto",
  },
  clinicBox: {
    height: "100px",
    textAlign: "center",
  },
  appointmentBox: {
    my: 2,
    textAlign: "center",
  },
};

export default styles;
