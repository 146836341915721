// React
import React from "react";

// MUI
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Styles
import { styles } from "./styles";

const DuplicatePHINModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="duplicate-phin-dialog"
      sx={styles.dialog}
    >
      <DialogTitle sx={styles.duplicateTitle}>Duplicate PHIN</DialogTitle>
      <DialogContent>
        <DialogContentText>
          An active patient with the same health card number already exists on
          your account. <br />
          Please try again with a different health card number.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} sx={styles.okButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicatePHINModal;
