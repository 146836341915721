// Colours
import { emeraldGreen, terracotta } from "Constants/ColourConstants";

// Material UI
import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

export const styles = {
  navBar: {
    background: emeraldGreen,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 20,
    fontWeight: "bold",
    cursor: "pointer",
  },
  signOutButton: {
    background: "white",
    borderRadius: 30,
    color: terracotta,
    ml: "auto",
  },
};

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));
