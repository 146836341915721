// Colour
import { offWhite } from "Constants/ColourConstants";

export const styles = {
  background: {
    minHeight: "97vh",
    paddingBottom: "3vh",
    backgroundColor: offWhite,
  },
  snackbar: {
    mt: 10,
  },
};
