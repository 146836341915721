// React
import React, { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Styles
import { styles } from "./styles";

// MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";

// Resources
import { deactivatePatient } from "API/Resource/patient";

const DeactivatePatientModal = ({ open, patient, onClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeactivatePatient = async () => {
    try {
      setLoading(true);
      await deactivatePatient(patient.id);
      setLoading(false);
      onClose();
      dispatch(
        openSnackbar({
          message: "Patient successfully deactivated!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        <Typography sx={styles.headingModal}>DEACTIVATE PATIENT</Typography>
        <Typography sx={styles.infoTextModal}>
          Are you sure you want to deactivate{" "}
          <strong>
            {patient.firstName} {patient.lastName}
          </strong>
          ?
        </Typography>
        <Typography color="error" sx={{ width: "100%", textAlign: "left" }}>
          All upcoming appointments will be cancelled.
        </Typography>

        <Box sx={styles.buttonContainerModal}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={styles.cancelButtonModal}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeactivatePatient}
            variant="contained"
            sx={styles.submitButtonModal}
          >
            {loading ? (
              <CircularProgress size={"24px"} sx={{ color: "#FFFF" }} />
            ) : (
              "DEACTIVATE"
            )}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeactivatePatientModal;
