import { get, post, put, patch } from "../../Utils/HTTPMethods";

export const getAllClinics = async (isActiveOnly = true) => {
  return await get("QBookingResource", `/clinic`, { isActiveOnly });
};

export const getClinic = async (id) => {
  return await get("QBookingResource", `/clinic`, { id });
};

export const createClinic = async (clinic) => {
  return await post("QBookingResource", `/clinic`, clinic);
};

export const updateClinic = async (clinic) => {
  return await put("QBookingResource", `/clinic`, clinic);
};

export const updateAccuroInfo = async (id, accuroAuthCode) => {
  return await patch("QBookingResource", `/clinic`, { id, accuroAuthCode });
};

export const getActivateURL = async (id) => {
  return await get("QBookingResource", `/clinic`, { id, action: "activate" });
};
