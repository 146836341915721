// Colours
import { terracotta, emeraldGreen } from "Constants/ColourConstants";

export const styles = {
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  cancelButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
    width: 125,
  },
  submitButton: {
    borderRadius: 30,
    background: terracotta,
    width: 125,
  },
};
