// Colours
import { terracotta, emeraldGreen } from "../../../Constants/ColourConstants";

export const styles = {
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  formBox: {
    mt: 3,
    display: "flex",
    flexDirection: "column",
    gap: "5vh",
  },
  textFieldsBox: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  signinBtn: {
    borderRadius: 30,
    background: terracotta,
  },
  forgotPasswordBtn: {
    color: emeraldGreen,
    fontSize: 12,
    fontWeight: "bold",
  },
};
