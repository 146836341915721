import React from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./styles";
import { DateTime } from "luxon";
import { getChipColor } from "../Common/commonComponents";

const UpcomingPastAppointmentsMobile = ({
  filteredAppointments,
  selectedTab,
  searchClinic,
  loading,
  setSearchClinic,
  setOpenConfirmDelete,
  setSelectedRow,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.textFieldBox}>
        <TextField
          id="search-clinic"
          label="Search Clinics"
          variant="outlined"
          size="small"
          sx={styles.textField}
          value={searchClinic}
          onChange={(e) => setSearchClinic(e.target.value)}
        />
      </Box>

      <Box sx={styles.mobileCardsBox}>
        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        ) : filteredAppointments.length === 0 ? (
          <Typography align="center" sx={{ mt: 4 }}>
            No Appointments Available
          </Typography>
        ) : (
          <Grid container spacing={6} justifyContent="center">
            {filteredAppointments.map((appointment, index) => (
              <Card
                sx={{
                  ...styles.card,
                  height:
                    selectedTab === "Upcoming Appointments" ? "350px" : "280px",
                }}
              >
                <Chip
                  label={appointment.status}
                  sx={{
                    ...styles.chip,
                    backgroundColor: getChipColor(appointment.status),
                  }}
                />
                <CardContent sx={{ paddingTop: "42px" }}>
                  <Box sx={styles.clinicBox}>
                    <Typography sx={styles.cardBoldText}>
                      {appointment.name}
                    </Typography>
                    <Typography sx={styles.cardSubText}>
                      {appointment.address}
                    </Typography>
                  </Box>
                  {/* Appointment Details */}
                  <Box sx={styles.appointmentBox}>
                    <Typography sx={styles.cardMainText}>
                      {DateTime.fromISO(appointment.date).toFormat(
                        "MMMM dd, yyyy"
                      )}
                    </Typography>
                    <Typography sx={styles.cardMainText}>
                      {appointment.startTime}
                    </Typography>
                  </Box>
                </CardContent>

                {/* Action Buttons */}
                {selectedTab === "Upcoming Appointments" && (
                  <CardActions>
                    <Box sx={styles.cancelAppointmentBox}>
                      <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={() => {
                          setSelectedRow(appointment.id);
                          setOpenConfirmDelete(true);
                        }}
                      >
                        Cancel Appointment
                      </Button>
                    </Box>
                  </CardActions>
                )}
              </Card>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default UpcomingPastAppointmentsMobile;
