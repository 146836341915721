import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";
import { selectClient } from "Store/ClientSlice";

// MUI
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

// Styles
import { styles } from "./styles";
import { white } from "Constants/ColourConstants";

// Components
import { fetchAppointments } from "../Appointments/Common/fetchAppointments";

const ReasonForVisitModal = (props) => {
  const { setOpenReasonForVisit } = props;
  const navigate = useNavigate();
  const [stateData, setStateData] = useState({
    reasonForVisit: "",
    additionalInfo: "",
    availableSlots: [],
  });
  const [submit, setSubmit] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const patient = useSelector(selectPatient);
  const client = useSelector(selectClient);
  const appointmentFilter =
    client?.appointmentFilter === "LOCATION"
      ? client?.appointmentLocations
      : client?.appointmentReasons;

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  useEffect(() => {
    if (!loading && submit) {
      navigate("/Appointments", { state: stateData });
    }
  }, [loading, submit, stateData, navigate]);

  const handleSubmit = () => {
    setSubmit(true);
  };

  const handleFetchAppointments = async () => {
    setLoading(true);
    const appointments = await fetchAppointments(
      patient,
      stateData.reasonForVisit
    );
    updateStateData("availableSlots", appointments);
    setLoading(false);
  };

  const handleNext = async () => {
    setStep(2);
    await handleFetchAppointments();
  };

  const updateStateData = (key, value) => {
    setStateData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Box
      sx={styles.modalBackdrop}
      onClick={() => setOpenReasonForVisit(false)} // Close modal when clicking outside the box
    >
      <Box
        sx={styles.modalContainer}
        onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside the box
      >
        {step === 1 ? (
          <>
            <Typography sx={styles.heading}>
              {client?.appointmentFilter === "LOCATION"
                ? "Select your appointment location"
                : "What is your reason for visit?"}
            </Typography>
            <TextField
              select
              disabled={submit}
              value={stateData.reasonForVisit}
              onChange={(event) =>
                updateStateData("reasonForVisit", event.target.value)
              }
              variant="outlined"
              fullWidth
              sx={styles.selectField}
            >
              {appointmentFilter.map((option) => (
                <MenuItem key={option} value={option} sx={styles.menuItem}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={styles.buttonContainer}>
              <Button
                onClick={() => setOpenReasonForVisit(false)}
                variant="outlined"
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleNext}
                variant="contained"
                disabled={!stateData.reasonForVisit}
                sx={styles.submitButton}
              >
                Next
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={styles.heading}>
              Please provide detailed information about your medical issue
            </Typography>
            <TextField
              multiline
              rows={4}
              disabled={submit}
              value={stateData.additionalInfo}
              onChange={(event) =>
                updateStateData("additionalInfo", event.target.value)
              }
              variant="outlined"
              fullWidth
              placeholder="Enter details here"
              sx={styles.textField}
              slotProps={{
                htmlInput: { maxLength: 400 },
              }}
              helperText={`${stateData.additionalInfo.length}/400 characters`}
            />

            <Box sx={styles.buttonContainer}>
              <Button
                onClick={() => setOpenReasonForVisit(false)}
                variant="outlined"
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                disabled={
                  !stateData.reasonForVisit || !stateData.additionalInfo
                }
                sx={styles.submitButton}
              >
                {submit ? (
                  <>
                    <CircularProgress
                      size={"14px"}
                      sx={{ color: white, marginRight: "8px" }}
                    />
                    Searching
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReasonForVisitModal;
