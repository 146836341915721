import { configureStore } from "@reduxjs/toolkit";

// Authentication
import AuthSlice from "./AuthSlice";
// Client
import ClientSlice from "./ClientSlice";

// Patient
import PatientSlice from "./PatientSlice";
// Snackbar
import SnackbarSlice from "./SnackbarSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    patient: PatientSlice,
    snackbar: SnackbarSlice,
    client: ClientSlice,
  },
});

export default store;
