// Yup
import { string, object, array } from "yup";

export const clientValidationSchema = object({
  name: string("Enter the clinic's name").required("Clinic name is required"),
  domain: string("Enter the clinic's domain").required(
    "Clinic domain is required"
  ),
  appointmentFilter: string("Select the appointment filter").required(
    "Appointment filter is required"
  ),
  appointmentLocations: array().when("appointmentFilter", {
    is: "LOCATION",
    then: () =>
      array()
        .of(string().required("Location is required"))
        .min(1, "One or more appointment locations must be entered"),
    otherwise: () => array(),
  }),
  appointmentReasons: array().when("appointmentFilter", {
    is: "REASON",
    then: () =>
      array()
        .of(string().required("Reason is required"))
        .min(1, "One or more appointment reasons must be entered"),
    otherwise: () => array(),
  }),
});
