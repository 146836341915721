// Colour
import { terracotta, emeraldGreen } from "Constants/ColourConstants";

export const styles = {
  searchGrid: {
    maxWidth: "1485px",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100px",
    alignItems: "center",
    justifyContent: "space-around",
  },
  searchField: {
    maxWidth: "50%",
    minWidth: "270px",
    alignItems: "center",
  },
  addPatientButton: {
    backgroundColor: terracotta,
    maxWidth: "100%",
    minWidth: "240px",
    borderRadius: 28,
  },
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 15,
    fontWeight: "medium",
  },
  personAddIcon: {
    paddingRight: "5%",
  },
  loading: {
    marginTop: "5%",
    color: emeraldGreen,
  },
};
