import { get, post, put } from "../../Utils/HTTPMethods";

export const getAllClients = async () => {
  return await get("QBookingResource", `/client`);
};

export const getClient = async (id) => {
  return await get("QBookingResource", `/client`, { id });
};

export const getClientByDomain = async (domain) => {
  return await get("QBookingResource", `/client`, { domain });
};

export const createClient = async (client) => {
  return await post("QBookingResource", `/client`, client);
};

export const updateClient = async (clientData) => {
  return await put(`QBookingResource`, `/client`, clientData);
};
