// Colours
import {
  emeraldGreen,
  grey,
  terracotta,
  white,
} from "Constants/ColourConstants";

export const styles = {
  pageBox: {
    maxWidth: "1000px",
    ml: "auto",
    mr: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    my: 2,
  },
  cardContainer: {
    borderRadius: 4,
    background: "white",
    pt: 2,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 20,
    color: emeraldGreen,
    textAlign: "left",
  },

  mainButton: {
    borderRadius: 30,
    background: terracotta,
    width: 125,
  },

  subtitle: {
    textTransform: "uppercase",
    fontWeight: "medium",
    fontSize: 16,
    color: terracotta,
    textAlign: "left",
  },
  filterRow: {
    marginBottom: 2,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  fab: {
    color: "white",
    background: emeraldGreen,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    ml: "auto",
  },
  secondaryButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
    width: 125,
  },
  filterText: {
    color: grey,
    "&.Mui-focused": {
      color: grey,
    },
  },
  formControl: {
    display: "flex",
    alignItems: "flex-start",
  },
  radioGroup: {
    flexDirection: "row",
    mx: 2,
    gap: { xs: 0, md: 5 },
  },
  dialogText: { textTransform: "uppercase", color: emeraldGreen },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: white,
      borderRadius: "8px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      width: "50%",
      minWidth: "315px",
      padding: 1,
    },
  },
};
