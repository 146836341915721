// Material UI
import { Snackbar, Alert } from "@mui/material";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { closeSnackbar, selectSnackbar } from "Store/SnackbarSlice";
import { selectAuthState } from "Store/AuthSlice";

const NotificationSnackbar = () => {
  const snackbarState = useSelector(selectSnackbar);
  const authState = useSelector(selectAuthState);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={snackbarState.keepOpen ? undefined : 6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ mt: authState === "SignedIn" ? 8 : 0 }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarState.severity}
        sx={{ width: "100%" }}
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
