import {
  offWhite,
  terracotta,
  grey,
  emeraldGreen,
} from "Constants/ColourConstants";

export const styles = {
  dialogPaper: {
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  dialogContent: {
    textAlign: "center",
    padding: "20px 24px",
  },
  dialogTitle: {
    marginBottom: "20px",
  },
  dialogActions: {
    justifyContent: "center",
    gap: 2,
    paddingBottom: "16px",
  },
  noButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  yesButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },
  confirmOkNoButton: {
    borderRadius: "30px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    minWidth: "120px",
  },

  dialogText: {
    color: grey,
  },
};

export default styles;
