// React
import React, { useState, useEffect } from "react";

// Amplify
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setAuthState, setUserEmail, selectUserEmail } from "Store/AuthSlice";
import { openSnackbar } from "Store/SnackbarSlice";

// Material UI
import {
  Box,
  Container,
  TextField,
  Typography,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import {
  MailOutline,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// Components
import { OTP } from "../Components/OTP";
import PasswordField from "Components/PasswordField";

// Formik
import { Formik } from "formik";

// Styles
import { styles } from "./styles";
import ConfirmSignUp from "../ConfirmSignUp";

// Validation
import {
  emailValidationSchema,
  passwordValidationSchema,
} from "Validation/UserValidation";

const ResetPassword = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isCodeStep, setIsCodeStep] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmSignupOpen, setConfirmSignupOpen] = useState(false);
  const userEmail = useSelector(selectUserEmail);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleForgotPassword = async (values) => {
    const { email } = values;

    try {
      const { nextStep } = await resetPassword({ username: email });
      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          setIsCodeStep(true);
          dispatch(setUserEmail(email));
          dispatch(
            openSnackbar({
              message: "Verification code successfully sent!",
              severity: "success",
            })
          );
          break;
        default:
          break;
      }
    } catch (error) {
      switch (error.name) {
        case "UserNotFoundException":
          dispatch(
            openSnackbar({
              message: "User with the given email does not exist",
              severity: "error",
            })
          );
          break;
        case "InvalidParameterException":
          dispatch(setUserEmail(email));
          setConfirmSignupOpen(true);
          break;
        default:
          dispatch(openSnackbar({ message: error.message, severity: "error" }));
          break;
      }
    }
  };

  const handleForgotPasswordSubmit = async (values) => {
    const { code, password } = values;

    try {
      await confirmResetPassword({
        username: userEmail,
        confirmationCode: code,
        newPassword: password,
      });
      dispatch(
        openSnackbar({
          message: "Password successfully reset!",
          severity: "success",
        })
      );
      dispatch(setAuthState("SignIn"));
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleBack = () => {
    dispatch(setAuthState("SignIn"));
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const ActionButtons = ({ buttonTitle, onSubmit }) => {
    return (
      <Box sx={styles.buttonsBox}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={styles.mainButton}
          onClick={onSubmit}
        >
          {buttonTitle}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          sx={styles.secondaryButton}
          onClick={handleBack}
        >
          Back
        </Button>
      </Box>
    );
  };

  return (
    <Container sx={styles.resetPasswordContriner}>
      <Typography sx={styles.title}>Reset Password</Typography>

      <Container sx={styles.contentBox}>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={emailValidationSchema}
          onSubmit={handleForgotPassword}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            handleSubmit,
          }) => (
            <form>
              <Box sx={styles.contentBox}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                  size={isMobile ? "small" : "medium"}
                  disabled={isCodeStep}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                {!isCodeStep && (
                  <ActionButtons
                    buttonTitle="Send Verification Code"
                    onSubmit={handleSubmit}
                  />
                )}
              </Box>
            </form>
          )}
        </Formik>

        {isCodeStep && (
          <Formik
            initialValues={{ code: "", password: "", confirmPassword: "" }}
            validationSchema={passwordValidationSchema}
            onSubmit={handleForgotPasswordSubmit}
          >
            {(formik) => (
              <form>
                <Box sx={styles.contentBox}>
                  <OTP
                    setCode={formik.setFieldValue}
                    resendCode={() =>
                      handleForgotPassword({ email: userEmail })
                    }
                    values={formik.values}
                    handleChange={formik.handleChange("code")}
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                  <PasswordField formik={formik} isMobile={isMobile} />
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm New Password"
                    type={showConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    required
                    size={isMobile ? "small" : "medium"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleConfirmPasswordToggle}
                              onMouseDown={handleConfirmPasswordToggle}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <ActionButtons
                    buttonTitle="Reset Password"
                    onSubmit={formik.handleSubmit}
                  />
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Container>

      <ConfirmSignUp
        open={confirmSignupOpen}
        onClose={() => {
          setConfirmSignupOpen(false);
          setIsCodeStep(true);
          handleForgotPassword({ email: userEmail });
          dispatch(
            openSnackbar({
              message:
                "Account successfully verified! A new verification code to reset your password has been sent to your email",
              severity: "success",
            })
          );
        }}
      />
    </Container>
  );
};

export default ResetPassword;
