// Colours
import {
  terracotta,
  emeraldGreen,
  textGrey,
} from "../../../Constants/ColourConstants";

export const styles = {
  headerBox: {
    display: "inline-block",
    marginLeft: "auto",
    marginRight: "auto",
    mt: "5vh",
  },
  title: {
    color: emeraldGreen,
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    fontSize: {
      xs: "2rem",
      sm: "2.5rem",
      lg: "3.5rem",
    },
  },
  subtitleBox: {
    align: "left",
    mt: "-10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  subtitle: {
    color: terracotta,
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    fontSize: {
      xs: "1rem",
      sm: "1.5rem",
    },
  },
  manitobaLogo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "50px",
    marginTop: "18px",
    marginBottom: "8px",
  },
  footerBox: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    paddingTop: 2,
  },
  techSupport: {
    fontSize: 16,
    fontWeight: "medium",
    color: textGrey,
  },
  email: {
    color: emeraldGreen,
    fontWeight: "bold",
  },
  copyRightBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    opacity: 0.6,
    pl: 2,
    pb: 2,
  },
  otpBox: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  otpInput: {
    minWidth: 250,
    marginLeft: "auto",
    marginRight: "auto",
  },
  otpButtonsBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    justifyContent: "center",
  },
  otpButtons: {
    borderRadius: "50px",
    padding: "8px 24px",
    minWidth: "150px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
};
