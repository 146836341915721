// Colours
import { offWhite } from "Constants/ColourConstants";

export const styles = {
  portalBox: {
    display: "flex",
    minHeight: "100vh",
  },
  mainComponent: {
    flexGrow: 1,
    p: 3,
    background: offWhite,
  },
  snackbar: {
    mt: 8,
  },
};
