import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";

// MUI
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Chip from "@mui/material/Chip";
// import CircularProgress from "@mui/material/CircularProgress";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import {
  ConfirmDeleteModal,
  ErrorDialog,
  getChipColor,
} from "./Common/commonComponents";

// Components
import { DateTime } from "luxon";
import TruncateText from "../../../../../Common/TruncateText";
import UpcomingPastAppointmentsMobile from "./Mobile/UpcomingPastAppointmentsMobile";

// Resources
import { getAppointmentsByPatientId } from "API/Resource/appointment";
import { getClinic } from "API/Resource/clinic";
import { cancelAppointment } from "API/Resource/appointment";

// Styles
import { styles } from "../styles";
// import {
//   alertSuccess,
//   alertError,
//   disabledText,
//   white,
// } from "Constants/ColourConstants";

// Constants
import { APPOINTMENT_INACTIVE_STATUS } from "Constants/AppointmentStatus";
const MOBILEVIEW = 850;

const UpcomingPastAppointments = () => {
  const [searchClinic, setSearchClinic] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Upcoming Appointments");
  const [appointments, setAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [gridHeight, setGridHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const patient = useSelector(selectPatient);
  const [loading, setLoading] = useState(true);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const getAppointments = async () => {
    const result = await getAppointmentsByPatientId(patient.id);

    const updatedResult = await Promise.all(
      result.map(async (appointment) => {
        // Fetch clinic information for each clinicId
        const clinicResult = await getClinic(appointment.clinicId);
        return {
          ...appointment,
          name: clinicResult.name,
          address: clinicResult.address,
        };
      })
    );
    setAppointments(updatedResult);
    setLoading(false);
  };

  const deleteAppointment = async (appointment) => {
    setLoadingDelete(true);
    try {
      await cancelAppointment(appointment);
      // remove the appointment from appointments and filteredAppointments
      const updatedAppointments = appointments.filter(
        (appt) => appt.id !== appointment
      );
      setAppointments(updatedAppointments);
      setFilteredAppointments(updatedAppointments);
    } catch (error) {
      const errorMessages = {
        APPOINTMENT_TIME_PASSED:
          "You cannot cancel this appointment because the appointment start time has already passed.",
      };
      const defaultErrorMessage =
        "Oops! Something went wrong while cancelling your appointment. Please try again later.";

      const errorMessage = errorMessages[error.message] || defaultErrorMessage;
      setErrorMessage(errorMessage);
      setOpenErrorDialog(true);
    } finally {
      setOpenConfirmDelete(false);
      setLoadingDelete(false);
    }
  };

  // Sort Appointments into upcoming and past
  const sortAppointments = () => {
    if (appointments.length === 0) return;

    const currentDateTime = DateTime.now().setZone("America/Winnipeg");

    // Sort all appointments in descending order
    const sortedAppointments = [...appointments].sort((a, b) => {
      const appointmentDateTimeA = DateTime.fromFormat(
        `${a.date} ${a.endTime}`,
        `yyyy-MM-dd ${"hh:mm a"}`
      );
      const appointmentDateTimeB = DateTime.fromFormat(
        `${b.date} ${b.endTime}`,
        `yyyy-MM-dd ${"hh:mm a"}`
      );
      return appointmentDateTimeB - appointmentDateTimeA;
    });

    let pastAppointments = [];
    let upcomingAppointments = [];

    // After sorting, separate into past and upcoming appointments
    sortedAppointments.forEach((appointment) => {
      if (APPOINTMENT_INACTIVE_STATUS.includes(appointment.status)) {
        pastAppointments.push(appointment);
        return;
      }

      const appointmentDateTime = DateTime.fromFormat(
        `${appointment.date} ${appointment.endTime}`,
        `yyyy-MM-dd ${"hh:mm a"}`
      );

      if (appointmentDateTime < currentDateTime) {
        pastAppointments.push(appointment);
      } else {
        upcomingAppointments.push(appointment);
      }
    });

    setPastAppointments(pastAppointments);
    setUpcomingAppointments(upcomingAppointments);
    setFilteredAppointments(
      selectedTab === "Upcoming Appointments"
        ? upcomingAppointments
        : pastAppointments
    );
  };

  const columns = (screenWidth) => [
    {
      field: "date",
      headerName: "Date",
      minWidth: screenWidth < MOBILEVIEW ? 140 : 150,
      flex: 1,
      renderCell: (params) =>
        DateTime.fromISO(params.row?.date).toFormat("MMMM dd, yyyy") || "",
    },
    {
      field: "time",
      headerName: "Time",
      minWidth: 85,
      flex: 1,
      renderCell: (params) => params.row?.startTime || "",
    },
    {
      field: "name",
      headerName: "Clinic",
      minWidth: 180,
      flex: 2,
      renderCell: (params) => <TruncateText text={params.row?.name || ""} />,
    },
    {
      field: "address",
      headerName: "Location",
      minWidth: 170,
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <LocationOnIcon aria-hidden="true" />
          <TruncateText text={params.row?.address || ""} />
        </Box>
      ),
      headerClassName: "location-column-header",
      cellClassName: "location-cell",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 1.5,
      renderCell: (params) => {
        const status = params.row?.status || "";
        return (
          <Chip
            label={
              status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
            }
            sx={{
              backgroundColor: getChipColor(status),
              color: "white",
              fontWeight: "bold",
            }}
          />
        );
      },
    },
    ...(selectedTab === "Upcoming Appointments"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            minWidth: 80,
            flex: 1,
            renderCell: (params) => (
              <Box>
                <IconButton
                  aria-label="Delete appointment"
                  sx={{ color: "#D32F2F" }}
                  onClick={() => {
                    setSelectedRow(params.row.id);
                    setOpenConfirmDelete(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ),
          },
        ]
      : []),
  ];

  // Fetch appointments for the patient
  useEffect(() => {
    getAppointments();
  }, [patient]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update screenWidth on resize
  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  // Split appointments into past and upcoming
  useEffect(() => {
    sortAppointments();
  }, [appointments]);

  // Calculate datagrid height based on number of rows
  useEffect(() => {
    const baseHeight = 169;
    const increment = 60;
    const n =
      filteredAppointments.length === 0
        ? 1
        : Math.min(filteredAppointments.length, 10);
    let calculatedHeight = baseHeight + (n - 1) * increment;

    // Add 20px if screen width is less than 795 to accomodate for horizontal scrollbar
    if (screenWidth < 795) {
      calculatedHeight += 20;
    }
    setGridHeight(calculatedHeight);
  }, [filteredAppointments, screenWidth]);

  // Dynamically calculate the max width of the inner box based on screen width
  const calculateInnerBoxMaxWidth = () => {
    const threshold = 1375;
    const minScreenWidth = 950;
    const baseWidth = 80; // Base width as 80%

    if (screenWidth < threshold) {
      const widthBelowThreshold = threshold - screenWidth;
      // Calculate extra percentage to add (gradually from 80% to 100%)
      const extraPercentage = Math.min(
        (widthBelowThreshold / (threshold - minScreenWidth)) * 20, // Scale from 0% to 20% increase
        20 // Cap it at 20% extra
      );
      return `calc(${baseWidth}% + ${extraPercentage}%)`;
    }
    return `${baseWidth}%`;
  };

  // Logic for search bar (searches by clinic)
  useEffect(() => {
    const filterSlots = () => {
      const searchTerm = searchClinic.toLowerCase();

      if (searchTerm) {
        return selectedTab === "Upcoming Appointments"
          ? upcomingAppointments.filter((row) =>
              row?.name.toLowerCase().includes(searchTerm)
            )
          : pastAppointments.filter((row) =>
              row?.name.toLowerCase().includes(searchTerm)
            );
      } else {
        return selectedTab === "Upcoming Appointments"
          ? upcomingAppointments
          : pastAppointments;
      }
    };
    const result = filterSlots();
    setFilteredAppointments(result);
  }, [searchClinic, selectedTab, upcomingAppointments, pastAppointments]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.innerBox,
          maxWidth: calculateInnerBoxMaxWidth(),
        }}
      >
        <Box sx={styles.flexStartContainer}>
          <Button
            variant="contained"
            sx={styles.backButton}
            onClick={() => navigate("/")}
          >
            <ArrowBackIcon sx={styles.backArrow} />
            <Typography sx={styles.backButtonText}>BACK</Typography>
          </Button>
        </Box>

        <Box sx={styles.patientNameBox}>
          <Typography sx={styles.patientName}>
            Patient: {patient.firstName} {patient.lastName}
          </Typography>
        </Box>
        <Box sx={styles.tabContainer}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={styles.tabs}
            TabIndicatorProps={{ sx: styles.tabIndicator }}
            aria-label="Appointments tabs"
            indicatorColor="#CB6843"
            textColor="#CB6843"
          >
            <Tab
              label="Upcoming Appointments"
              value="Upcoming Appointments"
              id="upcoming-appointments-tab"
              aria-controls="upcoming-appointments-panel"
              aria-label="Upcoming Appointments Tab"
            />
            <Tab
              label="Past Appointments"
              value="Past Appointments"
              id="past-appointments-tab"
              aria-controls="past-appointments-panel"
              aria-label="Past Appointments Tab"
            />
          </Tabs>
        </Box>

        <Box sx={styles.flexStartContainer}>
          <Typography sx={styles.typography}>
            Please note, updates may take up to 30 minutes to appear.
          </Typography>
        </Box>

        {screenWidth < MOBILEVIEW ? (
          <UpcomingPastAppointmentsMobile
            filteredAppointments={filteredAppointments}
            selectedTab={selectedTab}
            searchClinic={searchClinic}
            loading={loading}
            setSearchClinic={setSearchClinic}
            setOpenConfirmDelete={setOpenConfirmDelete}
            setSelectedRow={setSelectedRow}
          />
        ) : (
          <Box sx={styles.fullWidthBox}>
            <Paper sx={styles.upcomingPastPaper}>
              <Box sx={styles.fullWidthBox}>
                <Box sx={styles.searchFieldBox}>
                  <TextField
                    id="search-clinic"
                    label="Search Clinic"
                    variant="outlined"
                    size="small"
                    sx={{
                      ...styles.textField,
                      width: screenWidth < MOBILEVIEW ? "50%" : "20%",
                    }}
                    value={searchClinic}
                    onChange={(e) => setSearchClinic(e.target.value)}
                  />
                </Box>
                <Box sx={styles.dataGridContainer}>
                  <DataGrid
                    rows={filteredAppointments}
                    columns={columns(screenWidth)}
                    rowHeight={60}
                    autoHeight
                    initialState={{
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5]}
                    disableColumnMenu={screenWidth < MOBILEVIEW}
                    sx={{
                      ...styles.dataGrid,
                      height: `${gridHeight}px`,
                    }}
                    localeText={{
                      noRowsLabel: loading
                        ? "Loading Appointments..."
                        : `No ${selectedTab}`,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>

      <ConfirmDeleteModal
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={() => deleteAppointment(selectedRow)}
        loading={loadingDelete}
      />

      <ErrorDialog
        open={openErrorDialog}
        onClose={handleErrorDialogClose}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

export default UpcomingPastAppointments;
