// React
import React, { useState, useEffect } from "react";

// Amplify
import { confirmSignUp, signIn, resendSignUpCode } from "aws-amplify/auth";

// Material UI
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { MailOutline } from "@mui/icons-material";

// Components
import { AuthFooter } from "../Components/AuthFooter";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserEmail,
  setAuthState,
  selectAuthState,
} from "../../../Store/AuthSlice";
import { openSnackbar } from "Store/SnackbarSlice";

// Colours
import { emeraldGreen } from "../../../Constants/ColourConstants";
import { OTP } from "../Components/OTP";

// Formik
import { useFormik } from "formik";
import * as yup from "yup";

// Styles
import { styles } from "./styles";

// Validation schema
const validationSchema = yup.object({
  code: yup
    .string("Enter verification code")
    .length(6, "Verification code must be 6 digits")
    .required("Verification code is required"),
});

const ConfirmSignUp = ({ open, onClose, password }) => {
  const userEmail = useSelector(selectUserEmail);
  const authState = useSelector(selectAuthState);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (open && authState !== "SignUp") {
      handleResendCode();
    }
  }, [open]);

  const handleConfirmSignUp = async (code) => {
    try {
      await confirmSignUp({
        username: userEmail,
        confirmationCode: code,
      });
      dispatch(
        openSnackbar({
          message:
            authState === "SignUp"
              ? "Account successfully created!"
              : "Account successfully verified!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
      return;
    }

    if (authState === "ResetPassword") {
      onClose();
    } else {
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: userEmail,
          password,
        });
        if (isSignedIn && nextStep.signInStep === "DONE") {
          dispatch(setAuthState("SignedIn"));
        }
      } catch (error) {
        dispatch(openSnackbar({ message: error.message, severity: "error" }));
        onClose();
      }
    }
  };

  const handleResendCode = async () => {
    try {
      await resendSignUpCode({ username: userEmail });
      dispatch(
        openSnackbar({
          message: "New code has been resent!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleConfirmSignUp(values.code);
    },
  });

  return (
    <Dialog open={open} component="form" onSubmit={formik.handleSubmit}>
      <DialogTitle color={emeraldGreen} sx={{ textTransform: "uppercase" }}>
        {authState === "SignUp" ? "Confirm Sign Up" : "Verify Account"}
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <DialogContentText>
          {authState === "SignUp"
            ? "To verify your account, a verification code has been sent to your email. Please enter it below."
            : "An account with this email exists but it is not verified yet. To procceed, please verify your account by entering the verifiation code sent to your email."}
        </DialogContentText>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          required
          size={isMobile ? "small" : "medium"}
          value={userEmail}
          disabled
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : "Please check your spam or junk folder if you do not receive the email."
          }
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
            },
          }}
        />
        <OTP
          setCode={formik.setFieldValue}
          resendCode={handleResendCode}
          values={formik.values}
          handleChange={formik.handleChange("code")}
          errors={formik.errors}
          touched={formik.touched}
        />
        <AuthFooter showCopyright={false} />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" sx={styles.submitButton}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSignUp;
