import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Redux
import { selectPatient } from "Store/PatientSlice";

// MUI
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";

// Styles
import { styles } from "./styles";
import {
  terracotta,
  emeraldGreen,
  dateNotAvailableGrey,
  dateNotSelectedGrey,
} from "Constants/ColourConstants";

// Components
import { DateTime } from "luxon";

const ViewMoreAppointmentsModal = (params) => {
  const { row, bookAppointment, closeModal, reasonForVisit, additionalInfo } =
    params;
  const [availableSlots, setAvailableSlots] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [selectedSubColumn, setSelectedSubColumn] = useState("");
  const [selectedPhysician, setSelectedPhysician] = useState("");
  const [period, setPeriod] = useState("AM");
  const dates = Object.keys(availableSlots);
  const patient = useSelector(selectPatient);

  // Prevent scrolling on background when modal is open
  useEffect(() => {
    document.body.style.overflow = "hidden";

    // Cleanup on modal close
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // Extract available slots from the row data
  // Final object structure:
  // {
  //   "2022-12-25": {
  //     "AM": {
  //       "9:00 AM": "Dr. John Doe",
  //       "9:30 AM": "Dr. John Doe",
  //     },
  //     "PM": {
  //       "1:00 PM": "Dr. Jane Doe",
  //       "1:30 PM": "Dr. Jane Doe",
  //     },
  //   },
  // }
  useEffect(() => {
    const slots = row.availableSlots.reduce((acc, slot) => {
      // Extract date part from slot
      const dateKey = slot.date.split("T")[0];

      // Initialize AM and PM if not already present
      if (!acc[dateKey]) {
        acc[dateKey] = { AM: {}, PM: {} };
      }

      // Assume slot.startTime and slot.endTime are in the format "9:00 AM"
      const startTimeStr = slot.startTime;
      const endTimeStr = slot.endTime;

      // Parse the start time using DateTime from Luxon
      const startTimeObj = DateTime.fromFormat(startTimeStr, "h:mm a");

      if (!startTimeObj.isValid) {
        // Skip invalid time formats
        return acc;
      }

      // Determine if it's AM or PM
      const period = startTimeObj.hour < 12 ? "AM" : "PM";

      // Use timeStr directly since it's already formatted as 'h:mm a'
      const timeSlotId = `${dateKey}_${startTimeStr}`;

      // Store the start time, end time, and physician under the respective period
      acc[dateKey][period][timeSlotId] = {
        startTime: startTimeStr,
        endTime: endTimeStr,
        physician: slot.providerName,
        providerId: slot.providerId,
        subColumn: slot.subColumn,
      };

      return acc;
    }, {});

    setAvailableSlots(slots);

    const firstAvailableDate = Object.keys(slots).find(
      (date) =>
        (date >= DateTime.now().toISODate() &&
          Object.keys(slots[date]["AM"]).length > 0) ||
        Object.keys(slots[date]["PM"]).length > 0
    );
    if (firstAvailableDate) {
      setSelectedDate(firstAvailableDate);
    }
    const firstAvailablePeriod = Object.keys(slots[firstAvailableDate]).find(
      (period) => Object.keys(slots[firstAvailableDate][period]).length > 0
    );
    if (firstAvailablePeriod) {
      setPeriod(firstAvailablePeriod);
    }
  }, [row.availableSlots]);

  const createAppointmentDetails = () => {
    if (!selectedTime || !selectedDate) return;
    const appointment = {
      patientId: patient.id,
      clinicId: row.Clinic.id,
      clinicName: row.Clinic.name,
      address: row.Clinic.location,
      physician: selectedPhysician,
      date: DateTime.fromISO(selectedDate).toFormat("MMM d, yyyy"),
      startTime: selectedTime,
      endTime: selectedEndTime,
      type: reasonForVisit,
      notes: `Online Booking: ${additionalInfo}`,
      status: "BOOKED",
      accuroDetails: {
        subColumn: selectedSubColumn,
        providerId: selectedProviderId,
      },
    };
    bookAppointment(appointment);
    closeModal();
  };

  // Need to instead just pull the object from the row
  const selectTimeSlot = (
    timeSlotId,
    startTime,
    endTime,
    physician,
    providerId,
    subColumn
  ) => {
    setSelectedTimeSlotId(timeSlotId);
    setSelectedTime(startTime);
    setSelectedEndTime(endTime);
    setSelectedPhysician(physician);
    setSelectedProviderId(providerId);
    setSelectedSubColumn(subColumn);
  };

  return (
    <>
      <Box sx={styles.overlay} onClick={closeModal}>
        <Box sx={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
          <Box sx={styles.header}>
            <Typography sx={styles.clinicName}>
              {`${(
                row.Clinic.name || ""
              ).toUpperCase()} AVAILABLE APPOINTMENTS`}
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            {/* Date tabs*/}
            <Box sx={styles.tabsContainer}>
              {(() => {
                // Loop through 7 days starting from today
                const dateTabs = Array.from({ length: 7 }).map((_, index) => {
                  const currentDate = DateTime.now()
                    .plus({ days: index })
                    .toISODate();
                  const isDateAvailable = dates.includes(currentDate);
                  const formattedDate =
                    DateTime.fromISO(currentDate).toFormat("MMM d");

                  return (
                    <Tab
                      key={index}
                      label={formattedDate}
                      disabled={!isDateAvailable}
                      sx={{
                        color: !isDateAvailable
                          ? dateNotAvailableGrey
                          : currentDate === selectedDate
                          ? terracotta
                          : dateNotSelectedGrey,
                        fontWeight: !isDateAvailable
                          ? "normal"
                          : currentDate === selectedDate
                          ? "bold"
                          : "500",
                      }}
                    />
                  );
                });

                const selectedTabIndex = selectedDate
                  ? Array.from({ length: 7 }).findIndex(
                      (_, index) =>
                        DateTime.now().plus({ days: index }).toISODate() ===
                        selectedDate
                    )
                  : 0;

                return (
                  <Tabs
                    value={selectedTabIndex}
                    onChange={(event, newValue) => {
                      const currentDate = DateTime.now()
                        .plus({ days: newValue })
                        .toISODate();
                      setSelectedDate(currentDate);
                    }}
                    variant="scrollable"
                    scrollButtons={true}
                    allowScrollButtonsMobile
                    sx={styles.dateTabs}
                    TabIndicatorProps={{
                      style: { backgroundColor: terracotta },
                    }}
                    aria-label="date tabs"
                    indicatorColor="terracotta"
                    textColor="terracotta"
                  >
                    {dateTabs}
                  </Tabs>
                );
              })()}
            </Box>
            {/* AM/PM tabs */}
            <Box sx={styles.periodTabsContainer}>
              <Tabs
                value={period === "AM" ? 0 : 1}
                onChange={(_, newValue) =>
                  setPeriod(newValue === 0 ? "AM" : "PM")
                }
                sx={styles.periodTabs}
                TabIndicatorProps={{ style: { backgroundColor: terracotta } }}
                indicatorColor="terracotta"
                textColor="terracotta"
                variant="fullWidth"
                aria-label="am-pm-tabs"
              >
                <Tab label="AM" />
                <Tab label="PM" />
              </Tabs>
            </Box>

            {/* Time slots */}
            <Box sx={styles.timeSlotsContainer}>
              {availableSlots[selectedDate] &&
              Object.keys(availableSlots[selectedDate][period]).length > 0 ? (
                Object.entries(availableSlots[selectedDate][period]).map(
                  ([
                    timeSlotId,
                    { startTime, endTime, physician, providerId, subColumn },
                  ]) => {
                    return (
                      <Button
                        key={timeSlotId}
                        sx={{
                          ...styles.timeSlotButton,
                          fontWeight:
                            selectedTimeSlotId === timeSlotId ? "700" : "400",
                          border:
                            selectedTimeSlotId === timeSlotId
                              ? "2px solid" + emeraldGreen
                              : "none",
                        }}
                        onClick={() =>
                          selectTimeSlot(
                            timeSlotId,
                            startTime,
                            endTime,
                            physician,
                            providerId,
                            subColumn
                          )
                        }
                      >
                        {startTime} {/* Only display the start time */}
                      </Button>
                    );
                  }
                )
              ) : (
                <Typography
                  sx={{ color: dateNotSelectedGrey, fontWeight: 500 }}
                >
                  No appointments available
                </Typography>
              )}
            </Box>
            <Box sx={styles.actionButtonsContainer}>
              <Button
                variant="outlined"
                onClick={closeModal}
                sx={styles.cancelButton}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                sx={styles.bookButton}
                onClick={createAppointmentDetails}
                disabled={!selectedTime}
              >
                Book
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewMoreAppointmentsModal;
