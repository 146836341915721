export const styles = {
  requirement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 1,
  },
  requirementIcon: {
    height: 16,
    width: "auto"
  },
};
