// React
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Components
import NavigationBar from "Components/Users/Patient/NavigationBar";
import AvailableAppointments from "../Appointments/AvailableAppointments/AvailableAppointments";
import PatientEdit from "Components/PatientEdit";
import UpcomingPastAppointments from "../Appointments/UpcomingPastAppointments/UpcomingPastAppointments";
import PatientHome from "../PatientHome";
import ChangePassword from "Components/ChangePassword/ChangePassword";

// MUI
import { Box } from "@mui/material";

// Styles
import { styles } from "./styles";

const PatientPortal = () => {
  return (
    <Box sx={styles.background}>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<PatientHome />} />
        <Route path="/Appointments" element={<AvailableAppointments />} />
        <Route
          path="/AppointmentManagement"
          element={<UpcomingPastAppointments />}
        />
        <Route path="/PatientEdit" element={<PatientEdit />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
      </Routes>
    </Box>
  );
};

export default PatientPortal;
