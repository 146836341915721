import {
  terracotta,
  emeraldGreen,
} from "../../../../Constants/ColourConstants";

export const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    width: "43%",
    minWidth: "322px",
    padding: 3,
    position: "relative",
    zIndex: 1400,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    color: emeraldGreen,
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    textAlign: "left",
    width: "100%",
    mb: ".5rem",
  },
  selectField: {
    "& .MuiOutlinedInput-root": {
      borderColor: "gray",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: emeraldGreen,
    },
    "& label.Mui-focused": { color: emeraldGreen },
    "& .MuiInputBase-input": {
      textAlign: "left",
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  menuItem: {
    textAlign: "left",
  },
  infoText: {
    color: emeraldGreen,
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Roboto",
    textAlign: "left",
    width: "100%",
    mb: 1,
    marginTop: "2rem",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderColor: "gray",
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: emeraldGreen,
    },
    "& label.Mui-focused": { color: emeraldGreen },
    "& input::placeholder": {
      color: "gray",
      opacity: 1,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 2,
    mt: 2,
    width: "100%",
    marginTop: "2rem",
  },
  cancelButton: {
    width: "150px",
    borderRadius: "50px",
    padding: "8px 24px",
    borderColor: emeraldGreen,
    color: emeraldGreen,
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
  submitButton: {
    width: "150px",
    borderRadius: "50px",
    padding: "8px 24px",
    backgroundColor: terracotta,
    color: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
  },
};
