// Libraries
import { DateTime } from "luxon";

// Resources
import { getAvailableAppointments } from "API/Resource/appointment";

export const fetchAppointments = async (patient, reasonForVisit) => {
  // check patient age group
  const now = DateTime.now().setZone("America/Winnipeg");
  const birthDate = DateTime.fromISO(patient.dob);
  const isOver18 = now.diff(birthDate, "years").years >= 18;

  // get appointments by age group and reason for visit
  const result = await getAvailableAppointments(
    isOver18 ? "ADULT" : "YOUNG",
    reasonForVisit
  );

  // remove clinics with no available slots
  const appointments = result.filter(
    (clinic) => clinic.availableSlots.length > 0
  );

  return appointments;
};
