// React
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

// Amplify
import { signOut } from "aws-amplify/auth";

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";
import { setAuthState } from "Store/AuthSlice";
import { emeraldGreen } from "Constants/ColourConstants";

// Styles
import { styles } from "./styles";

// Constants
import {
  ONE_SECOND,
  THIRTY_SECONDS,
  FIFTEEN_MINUTES,
} from "Constants/TimeConstants";

const AutoSignOut = () => {
  const [remainingTime, setRemainingTime] = useState(
    Math.ceil(THIRTY_SECONDS / 1000)
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSignOut = async (onClick = false) => {
    try {
      await signOut();
      sessionStorage.clear();
      if (!onClick) {
        dispatch(
          openSnackbar({
            message: "You were signed out due to inactivity",
            severity: "error",
            keepOpen: true,
          })
        );
      }
      dispatch(setAuthState("SignIn"));
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout: FIFTEEN_MINUTES,
    promptBeforeIdle: THIRTY_SECONDS,
    throttle: ONE_SECOND,
    onIdle: handleSignOut,
    onActive: handleDialogClose,
    onPrompt: handleDialogOpen,
  });

  const handleActivate = () => {
    activate();
  };

  useEffect(() => {
    if (dialogOpen) {
      const interval = setInterval(() => {
        const remaining = getRemainingTime();
        setRemainingTime(Math.ceil(remaining / 1000));
      }, ONE_SECOND);

      return () => {
        clearInterval(interval);
      };
    }
  }, [getRemainingTime, dialogOpen]);

  return (
    <Dialog open={dialogOpen} onClose={handleDialogClose} sx={styles.dialog}>
      <DialogTitle color={emeraldGreen} sx={{ textTransform: "uppercase" }}>
        Stay Signed In?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You will be signed out in <strong>{remainingTime} seconds</strong> due
          to inactivity. Do you want to stay signed in?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.actionsContainer}>
        <Button
          variant="outlined"
          onClick={handleActivate}
          sx={styles.signInButton}
        >
          Stay Signed In
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSignOut(true)}
          sx={styles.signOutButton}
        >
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoSignOut;
