// Colours
import { terracotta, emeraldGreen } from "Constants/ColourConstants";

export const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    borderRadius: "8px",
    maxWidth: "800px",
    alignItems: "center",
    gap: 4,
    py: 3,
    px: "100px",
    mx: "auto",
    my: 2,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "@media (max-width: 1030px)": {
      mx: 2,
    },
    "@media (max-width: 725px)": {
      px: 5,
    },
    "@media (max-width: 420px)": {
      px: 1,
    },
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 20,
    color: emeraldGreen,
  },
  stepsGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "10px",
    "@media (max-width: 600px)": {
      width: "auto",
      justifyContent: "center",
      flexDirection: "column",
      gap: 5,
    },
  },
  stepGrid: {
    flexDirection: "column",
    gap: 2,
    "@media (max-width: 600px)": {
      flexDirection: "row",
      gap: 5,
    },
  },
  iconBox: {
    height: "80px",
  },
  typographyBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  },
  secondaryTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 13,
    color: terracotta,
    marginTop: 1,
    "@media (max-width: 600px)": {
      textAlign: "left",
      marginTop: 0,
    },
  },
  description: {
    fontSize: 13,
    "@media (max-width: 600px)": {
      textAlign: "left",
    },
  },
};
