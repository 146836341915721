const PhinModCheck = (phinNumber) => {
  if (phinNumber.length !== 9) return false;

  const digits = phinNumber.slice(0, 8).split("").map(Number);
  const primes = [29, 23, 19, 17, 13, 7, 5, 3];

  const sum = digits.reduce((acc, digit, i) => acc + digit * primes[i], 0);
  const modResult = (sum % 11) % 10;

  // Compare MOD-11 result with the check digit (9th digit)
  const checkDigit = parseInt(phinNumber[8], 10);

  return modResult === checkDigit;
};

export default PhinModCheck;
