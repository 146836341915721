// React
import React, { useState, useEffect } from "react";

// Amplify
import { signUp } from "aws-amplify/auth";

// Material UI
import {
  Container,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import {
  MailOutline,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// Formik
import { useFormik } from "formik";

// Components
import ConfirmSignUp from "../ConfirmSignUp";
import PasswordField from "Components/PasswordField";

// Redux
import { setUserEmail } from "../../../Store/AuthSlice";
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Styles
import { styles } from "./styles";

// Validation
import { signupValidationSchema } from "Validation/UserValidation";

const SignUp = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSignUp = async (email, password) => {
    try {
      await signUp({
        username: email,
        password,
        attributes: { email },
      });
      dispatch(setUserEmail(email));
      setConfirmOpen(true);
    } catch (error) {
      switch (error.name) {
        case "UsernameExistsException":
          dispatch(
            openSnackbar({
              message: "User already exists. Please sign in instead.",
              severity: "error",
            })
          );
          break;
        default:
          dispatch(openSnackbar({ message: error.message, severity: "error" }));
          break;
      }
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    validationSchema: signupValidationSchema,
    onSubmit: (values) => {
      handleSignUp(values.email, values.password);
    },
  });

  return (
    <Container sx={styles.noPadding}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.formBox}>
        <Box sx={styles.textFieldsBox}>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              },
            }}
          />
          <PasswordField formik={formik} isMobile={isMobile} />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleConfirmPasswordToggle}
                      onMouseDown={handleConfirmPasswordToggle}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="termsAndConditions"
                  name="termsAndConditions"
                  checked={formik.values.termsAndConditions}
                  onChange={formik.handleChange}
                  sx={styles.checkbox}
                />
              }
              label={
                <address align={"left"}>
                  I have read and agree to the terms and conditions as set out
                  by the{" "}
                  <a
                    href="https://sharedhealthmb.ca/wp-content/uploads/Medical-Navigator-Terms-of-Use.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of use and privacy policy
                  </a>
                </address>
              }
            />
            {formik.touched.termsAndConditions &&
              Boolean(formik.errors.termsAndConditions) && (
                <FormHelperText error sx={{ mx: "32px" }}>
                  {formik.errors.termsAndConditions}
                </FormHelperText>
              )}
          </Box>
        </Box>
        <Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={styles.signupBtn}
          >
            Sign Up
          </Button>
        </Box>
      </Box>

      <ConfirmSignUp
        open={confirmOpen}
        onClose={handleConfirmClose}
        password={formik.values.password}
      />
    </Container>
  );
};

export default SignUp;
