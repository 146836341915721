// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Amplify
import { updatePassword } from "aws-amplify/auth";

// Material UI
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";

// Styles
import { styles } from "./styles";

// Redux
import { useDispatch } from "react-redux";
import { openSnackbar } from "Store/SnackbarSlice";

// Formik
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthFooter } from "Components/Authentication/Components/AuthFooter";

// Validation schema
const validationSchema = yup.object({
  oldPassword: yup
    .string("Enter old password")
    .required("Old password is required"),
  password: yup
    .string("Enter a new password")
    .min(8, "New password must be at least 8 characters")
    .matches(
      /[^\w\s]/,
      "New password must contain at least one special character"
    )
    .required("New password is required"),
  confirmPassword: yup
    .string("Enter confirm new password")
    .oneOf([yup.ref("password"), null], "New passwords must match")
    .required("Confirm new password is required"),
});

const ChangePassword = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangePassword = async (oldPassword, newPassword) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      dispatch(
        openSnackbar({
          message: "Password changed successfully!",
          severity: "success",
        })
      );
      navigate("/");
    } catch (error) {
      dispatch(openSnackbar({ message: error.message, severity: "error" }));
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleOldPasswordToggle = () => {
    formik.setFieldValue("showOldPassword", !formik.values.showOldPassword);
  };

  const handlePasswordToggle = () => {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    formik.setFieldValue(
      "showConfirmPassword",
      !formik.values.showConfirmPassword
    );
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      showOldPassword: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showConfirmPassword: false,
    },
    validationSchema,
    onSubmit: (values) => {
      handleChangePassword(values.oldPassword, values.password);
    },
  });
  return (
    <Container
      component="form"
      onSubmit={formik.handleSubmit}
      sx={styles.mainContainer}
    >
      <Button
        variant="contained"
        onClick={handleNavigateBack}
        sx={styles.backButton}
      >
        <ArrowBack fontSize="small" />
        Back
      </Button>
      <Box sx={styles.contentBox}>
        <Typography sx={styles.title}>Change Password</Typography>

        <Box sx={styles.textFieldsBox}>
          <TextField
            id="oldPassword"
            name="oldPassword"
            label="Old Password"
            type={formik.values.showOldPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle old password visibility"
                      onClick={handleOldPasswordToggle}
                      onMouseDown={handleOldPasswordToggle}
                      edge="end"
                    >
                      {formik.values.showOldPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            id="password"
            name="password"
            label="New Password"
            type={formik.values.showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle new password visibility"
                      onClick={handlePasswordToggle}
                      onMouseDown={handlePasswordToggle}
                      edge="end"
                    >
                      {formik.values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm New Password"
            type={formik.values.showConfirmPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            required
            size={isMobile ? "small" : "medium"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle confirm new password visibility"
                      onClick={handleConfirmPasswordToggle}
                      onMouseDown={handleConfirmPasswordToggle}
                      edge="end"
                    >
                      {formik.values.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        <Button type="submit" variant="contained" sx={styles.submitButton}>
          Submit
        </Button>
      </Box>
      <AuthFooter showCopyright={false} />
    </Container>
  );
};

export default ChangePassword;
