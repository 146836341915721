// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setPatient, selectPatient } from "Store/PatientSlice";

// MUI
import { Card, Box, Typography, Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid2";

// Styles
import { styles } from "./styles";

// icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

// Components
import ReasonForVisitModal from "../ReasonForVisitModal/ReasonForVisitModal";
import DeactivatePatientModal from "./DeactivatePatientModal";
import MFA from "Components/MFA/MFA";

const PatientCards = (props) => {
  const { patients, fetchPatients } = props;
  const [openReasonForVisit, setOpenReasonForVisit] = useState(false);
  const [openMFA, setOpenMFA] = useState(false);
  const [onVerify, setOnVerify] = useState(null);
  const [action, setAction] = useState(null);
  const currPatient = useSelector(selectPatient);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const PatientCard = ({ patient }) => {
    const [deletePatientModal, setDeletePatientModal] = useState(false);
    const verified2FA =
      sessionStorage.getItem(`2FA${patient.id}`) === "verified";
    const getInitials = () => {
      const firstLetter = patient.firstName.charAt(0);
      const secondLetter = patient.lastName.charAt(0);
      return firstLetter + secondLetter;
    };

    useEffect(() => {
      if (
        !openMFA &&
        currPatient.id === patient.id &&
        verified2FA &&
        action === "DELETE"
      ) {
        setDeletePatientModal(true);
      }
    }, [openMFA]);

    const handleNavigation = (route) => {
      setOpenMFA(false);
      navigate(route);
    };

    const handleMFAClose = () => {
      setOpenMFA(false);
    };

    const handleMFAAction = (MFAAction, ...args) => {
      dispatch(setPatient(patient));
      if (verified2FA) {
        MFAAction(...args);
      } else {
        setOnVerify(() => () => MFAAction(...args));
        setOpenMFA(true);
      }
    };

    return (
      <Card sx={styles.card}>
        <Box sx={styles.header}></Box>
        <Box sx={styles.logoBox}>
          <Box sx={styles.logoBorder}></Box>
          <Box sx={styles.logo}>
            <Typography sx={styles.initials}>{getInitials()}</Typography>
          </Box>
        </Box>
        <Grid container sx={styles.grid}>
          <Grid size={3}>
            <IconButton
              sx={styles.createIcon}
              onClick={() => {
                setAction("EDIT");
                handleMFAAction(handleNavigation, "/PatientEdit");
              }}
            >
              <CreateIcon />
            </IconButton>
          </Grid>
          <Grid size={6} />
          <Grid size={3}>
            <IconButton
              sx={styles.deleteIcon}
              onClick={() => {
                setAction("DELETE");
                handleMFAAction(handleMFAClose);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid size={12} sx={styles.grid}>
            <Typography sx={styles.name}>
              {patient.firstName + " " + patient.lastName}
            </Typography>
          </Grid>
          <Grid size={12} sx={styles.grid}>
            {/* <Typography sx={styles.upcomingAppts}>
              UPCOMING APPOINTMENTS
            </Typography> */}
          </Grid>
          <Grid container sx={styles.apptGrid}>
            {/* <AppointmentDisplay appointments={patient.appointments} /> */}
          </Grid>
          <Grid container spacing={1.5} sx={styles.buttonContainer}>
            <Grid size={1} />
            <Grid size={10}>
              <Button
                variant="outlined"
                sx={styles.secondaryButton}
                onClick={() => {
                  setAction("APPOINTMENTS");
                  handleMFAAction(handleNavigation, "/AppointmentManagement");
                }}
              >
                APPOINTMENTS
              </Button>
            </Grid>
            <Grid size={1} />
            <Grid size={12}>
              <Button
                variant="contained"
                sx={styles.primaryButton}
                onClick={() => {
                  setOpenReasonForVisit(true);
                  dispatch(setPatient(patient));
                }}
              >
                BOOK APPOINTMENT
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <DeactivatePatientModal
          open={deletePatientModal}
          patient={patient}
          onClose={() => {
            setDeletePatientModal(false);
            setAction(null);
            fetchPatients();
          }}
        />
      </Card>
    );
  };

  return (
    <Box sx={styles.cardBox}>
      <Grid spacing={6} container sx={styles.cardGrid}>
        {patients.length > 0 ? (
          patients.map((patient) => (
            <PatientCard key={patient.id} patient={patient} />
          ))
        ) : (
          <Typography variant="h6" align="center" sx={styles.noPatients}>
            NO PATIENTS
          </Typography>
        )}
      </Grid>
      {openReasonForVisit && (
        <ReasonForVisitModal setOpenReasonForVisit={setOpenReasonForVisit} />
      )}

      <MFA
        open={openMFA}
        onClose={() => setOpenMFA(false)}
        onVerify={onVerify}
      />
    </Box>
  );
};

export default PatientCards;
