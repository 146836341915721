// Colours
import { terracotta, emeraldGreen } from "../../../Constants/ColourConstants";

export const styles = {
  resetPasswordContriner: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: {
      xs: "85%",
      md: "50%",
      lg: "30%",
    },
    minWidth: {
      lg: 450,
    },
    pt: 2,
  },
  contentBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  title: {
    color: emeraldGreen,
    textTransform: "uppercase",
    fontWeight: "medium",
    fontSize: 20,
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    mt: 4,
  },
  mainButton: {
    borderRadius: 30,
    background: terracotta,
  },
  secondaryButton: {
    borderRadius: 30,
    background: "white",
    color: emeraldGreen,
    borderColor: emeraldGreen,
  },
};
