// Material UI
import { Typography, Box } from "@mui/material";

// Styles
import { styles } from "./styles";

export const AuthHeader = () => {
  return (
    <>
      <Box
        component="img"
        src="/manitoba - resized.png"
        alt="QDoc Logo"
        sx={styles.manitobaLogo}
      />
      <Box sx={styles.headerBox}>
        <Typography sx={styles.title}>Medical Navigator</Typography>
      </Box>
    </>
  );
};
