import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Chip,
} from "@mui/material";
import {
  alertSuccess,
  alertError,
  disabledText,
  white,
} from "Constants/ColourConstants";
import styles from "./styles";

export const getChipColor = (status) => {
  switch (status.toLowerCase()) {
    case "booked":
      return alertSuccess;
    case "cancelled":
      return alertError;
    default:
      return disabledText;
  }
};

export const ConfirmDeleteModal = ({ open, onClose, onConfirm, loading }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
    <DialogContent sx={styles.dialogContent}>
      <Typography variant="h6" sx={styles.dialogTitle}>
        Are you sure you want to cancel this appointment?
      </Typography>
    </DialogContent>
    <DialogActions sx={styles.dialogActions}>
      <Button
        onClick={onClose}
        variant="outlined"
        disabled={loading}
        sx={styles.noButton}
      >
        No
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={loading}
        sx={styles.yesButton}
      >
        {loading ? (
          <>
            <CircularProgress
              size={"14px"}
              sx={{ color: white, marginRight: "8px" }}
            />
            Cancelling
          </>
        ) : (
          "Yes"
        )}
      </Button>
    </DialogActions>
  </Dialog>
);

export const ErrorDialog = ({ open, onClose, errorMessage }) => (
  <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialogPaper }}>
    <DialogContent sx={styles.dialogContent}>
      <Typography sx={styles.dialogText}>{errorMessage}</Typography>
    </DialogContent>
    <DialogActions sx={styles.dialogActions}>
      <Button
        variant="contained"
        onClick={onClose}
        sx={styles.confirmOkNoButton}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
);
