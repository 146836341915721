import { emeraldGreen, terracotta } from "Constants/ColourConstants";

export const styles = {
  mainContainer: {
    paddingTop: 5,
    display: "flex",
    flexDirection: "column",
    gap: 5,
    minHeight: "90vh",
  },
  title: {
    color: emeraldGreen,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 20,
  },
  backButton: {
    background: terracotta,
    borderRadius: 30,
    width: 125,
    justifyContent: "space-between",
  },
  formBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  contentBox: {
    width: {
      xs: "90%",
      sm: "60%",
      md: "40%",
    },
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  textFieldsBox: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  submitButton: {
    background: terracotta,
    borderRadius: 30,
    mb: 5,
  },
};
